import classNames from 'classnames/bind';
import { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const PrevArrow = ({ onClick, currentSlideIndex }) => {
  const isHidden = currentSlideIndex === 0;

  return (
    <div className={cx('arrow_fade_prev', { hide: isHidden })}>
      <div
        onClick={onClick}
        className={cx('arrow_button', 'arrow_button_prev')}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    </div>
  );
};
export default memo(PrevArrow);
