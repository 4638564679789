import fileUpload from '../../assets/homepage/promo/file-upload.png';
import alexa from '../../assets/homepage/promo/amazon-alexa.svg';
import star from '../../assets/homepage/promo/star.png';

export const promoSectionLarge = [
  {
    id: 1,
    iconData: {
      src: fileUpload,
      width: 63,
      height: 86,
    },
    title: 'Choose Your Investor Type, Asset Classes, and Topics & Securities',
    mainButton: {
      text: 'Personalise',
      url: '/news/onboarding/watchlist',
    },
    secondaryButton: null,
    big: true,
    type: 'files',
  },
  {
    id: 2,
    iconData: {
      src: alexa,
      width: 72,
      height: 84,
    },
    title: 'Manage Financial Content And Your Portfolios By Voice',
    mainButton: {
      text: 'Learn More',
      url: '/products/voiceassistant',
    },
    secondaryButton: null,
    type: 'voice',
    isReload: true,
  },
  {
    id: 3,
    iconData: {
      src: star,
      width: 91,
      height: 85,
    },
    title: 'Leverage Alternative Data To Find More Opportunities',
    mainButton: {
      text: 'Explore Data',
      url: '/directory/alternative-data',
    },
    secondaryButton: null,
    type: 'data',
  },
];

export default promoSectionLarge;
