import classNames from 'classnames/bind';

import Styles from '../styles.module.scss';

const cx = classNames.bind(Styles);

const Tabs = (props) => {
  const { data, setActiveVideo } = props;

  return (
    <div className={Styles['discovery-videos-tabs']}>
      {data.map(({ id, content, active }) => (
        <div
          key={id}
          className={cx('discovery-videos-tabs-btn', { active })}
          onClick={() => setActiveVideo(id)}
        >
          {content}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
