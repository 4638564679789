import { memo } from 'react';
import classNames from 'classnames/bind';

import DcscLogo from '../DcscLogo';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const DcscPoweredBy = ({ className = '' }) => (
  <div className={cx('dcsc-powered-by', className)}>
    <span className={Styles.label}>Powered by</span>
    <DcscLogo />
  </div>
);

export default memo(DcscPoweredBy);
