export const timeFilterMapping = new Map([
  ['1h', 'hour1'],
  ['1d', 'day1'],
  ['1w', 'week1'],
  ['1m', 'month1'],
  ['3m', 'months1'],
  ['1y', 'year1'],
  ['All', 'lifetime'],
]);

export default timeFilterMapping;
