import pluralize from 'pluralize';
import {
  LOCATION_TYPES,
  CONTINENT, SUBCONTINENT,
  COUNTRY, CITY,
  STATE, AREA, PLACE,
} from '../directory/topicTypes';

export const locationTypes = LOCATION_TYPES.slice(1).map((locationType, index) => ({
  id: locationType,
  content: pluralize(locationType).toLowerCase().replace(/^\w/, (l) => l.toUpperCase()),
  active: index === 0,
}));

locationTypes[1].content = 'Regions';

export const parentTypeMapping = new Map([
  [CONTINENT, SUBCONTINENT],
  [SUBCONTINENT, COUNTRY],
  [COUNTRY, STATE],
  [STATE, CITY],
  [CITY, AREA],
  [AREA, PLACE],
]);

export const locationTitlesMapping = new Map([
  [CONTINENT, 'continent'],
  [SUBCONTINENT, 'region'],
  [COUNTRY, 'country'],
  [STATE, 'state'],
  [CITY, 'city'],
  [AREA, 'area'],
  [PLACE, 'place'],
]);

export const childTypes = new Map([
  [CONTINENT, 'subcontinents'],
  [SUBCONTINENT, 'countries'],
  [COUNTRY, 'states'],
  [STATE, 'cities'],
  [CITY, 'areas'],
  [AREA, 'places'],
  [PLACE, ''],
]);

export const locationTypesTitles = {
  continents: 'Continents',
  subcontinents: 'Regions',
  countries: 'Countries',
  states: 'States',
  cities: 'Cities',
  areas: 'Areas',
  places: 'Places',
};
