import {
  memo,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandAlt } from '@fortawesome/pro-regular-svg-icons/faExpandAlt';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';

import SentimentAnalysisSection from './SentimentAnalysisSection';
import ItemsScrollBar from '../../Shared/ItemsScrollBar';
import Preloader from '../../Preloader';
import SentimentWorldChart from '../../SEO_pages/LocationsSentiment/SentimentWorldChart';

import { sortBySentiments } from '../../../helpers/sentimentHelpers';
import { checkFlexGapSupport } from '../../../helpers/helpers';

import SentimentsSvc from '../../../services/dbServices/SentimentsSvc';
import TopicsSvc from '../../../services/TopicsSvc';

import SectionDataContext from '../SectionWrapper/SectionDataContext';

import Styles from './styles.module.scss';

export const SentimentAnalysis = () => {
  const [topics, setTopics] = useState({
    locations: {
      positive: [],
      negative: [],
    },
    sectors: {
      positive: [],
      negative: [],
    },
  });
  const [topicClasses, setTopicClasses] = useState({
    noData: false,
    positive: [],
    negative: [],
  });
  const [topicClassesTabs, setTopicClassesTabs] = useState([]);
  const [activeTopicClassId, setActiveTopicClassId] = useState('all');
  const [loading, setLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [isGapSupport, setIsGapSupport] = useState(true);

  useEffect(() => {
    setIsGapSupport(checkFlexGapSupport());
  }, []);

  useEffect(() => {
    const callAPI = async () => {
      const data = await TopicsSvc.getTopicClasses();

      const tabData = data.map((obj) => ({
        ...obj,
        content: obj.title,
      }));

      tabData.unshift({
        id: 'all',
        content: 'ALL',
        active: true,
      });

      setTopicClassesTabs(tabData);
    };

    callAPI();
  }, []);

  const { sectionInView, selectedFilter } = useContext(SectionDataContext);

  useEffect(() => {
    if (!sectionInView) return;

    setLoading(true);

    const callListingsAPI = async (params) => {
      try {
        const { data } = await SentimentsSvc.getListingsSentiments(params);
        return data;
      } catch (e) {
        console.log(e);
      }
      return {};
    };

    if (activeTopicClassId === 'all') {
      callListingsAPI({
        entity_type: 'AssetClass',
        period: selectedFilter,
      })
        .then((data) => data.list)
        .then((topicClasses) => {
          setTopicClasses({
            noData: !topicClasses.length,
            positive: sortBySentiments({
              list: topicClasses,
              orderDir: 'DESC',
              type: 'positive',
              length: 30,
            }),
            negative: sortBySentiments({
              list: topicClasses,
              orderDir: 'ASC',
              type: 'negative',
              length: 30,
            }),
          });
        });
    } else {
      const params = {
        entity_type: 'AssetClass',
        entity_id: activeTopicClassId,
        per_page: 25,
        sorting: 'ascending',
        period: selectedFilter,
        sentiment_filter_mode: 'range',
        selection_mode: 'children',
      };

      Promise.all([
        callListingsAPI({
          ...params,
          sorting: 'descending',
          sentiment_greater_than_or_equal_to: '0',
          sentiment_less_than_or_equal_to: '100',
        }),
        callListingsAPI({
          ...params,
          sentiment_greater_than_or_equal_to: '-100',
          sentiment_less_than_or_equal_to: '-1',
        }),
      ])
        .then((response) => (
          response.map((data) => data.list || [])
        ))
        .then(([
          dataPositive,
          dataNegative,
        ]) => {
          setTopicClasses({
            noData: !dataPositive.length && !dataNegative.length,
            positive: dataPositive,
            negative: dataNegative,
          });
        });
    }

    setLoading(false);
  }, [sectionInView, selectedFilter, activeTopicClassId]);

  useEffect(() => {
    if (!sectionInView) return;

    setLoading(true);

    Promise.allSettled([
      SentimentsSvc.getListingsSentiments({
        entity_type: 'SectorLevel4',
        period: selectedFilter,
        per_page: 25,
        sentiment_greater_than_or_equal_to: '0',
        sentiment_less_than_or_equal_to: '100',
        sorting: 'descending',
        sentiment_filter_mode: 'range',
      }),
      SentimentsSvc.getListingsSentiments({
        entity_type: 'SectorLevel4',
        period: selectedFilter,
        per_page: 25,
        sentiment_greater_than_or_equal_to: '-100',
        sentiment_less_than_or_equal_to: '-1',
        sorting: 'ascending',
        sentiment_filter_mode: 'range',
      }),
      SentimentsSvc.getListingsSentiments({
        entity_type: 'Country',
        period: selectedFilter,
        per_page: 25,
        sentiment_greater_than_or_equal_to: '0',
        sentiment_less_than_or_equal_to: '100',
        sorting: 'descending',
        sentiment_filter_mode: 'range',
      }),
      SentimentsSvc.getListingsSentiments({
        entity_type: 'Country',
        period: selectedFilter,
        per_page: 25,
        sentiment_greater_than_or_equal_to: '-100',
        sentiment_less_than_or_equal_to: '-1',
        sorting: 'ascending',
        sentiment_filter_mode: 'range',
      }),
    ]).then((response) => (
      response.map(({ status, value }) => (
        status === 'fulfilled' ? value.data.list : []
      ))
    )).then(([
      sectorsPositive,
      sectorsNegative,
      countriesPositive,
      countriesNegative,
    ]) => {
      setTopics({
        locations: {
          positive: countriesPositive.slice(0, 10),
          negative: countriesNegative.slice(0, 10),
        },
        sectors: {
          positive: sectorsPositive.slice(0, 10),
          negative: sectorsNegative.slice(0, 10),
        },
      });

      setLoading(false);
      setInitialLoading(false);
    });
  }, [sectionInView, selectedFilter]);

  const handleTopicTabClick = (id) => {
    setTopicClassesTabs(topicClassesTabs.map((obj) => ({
      ...obj,
      active: obj.id === id,
    })));
    setActiveTopicClassId(id);
  };

  return (
    <div className={Styles['sentiment-analysis']}>
      <div className={Styles['sentiment-analysis-header']}>
        <Link
          to="/directory/sentiment/locations"
          className={Styles['sentiment-analysis-expand']}
        >
          <FontAwesomeIcon icon={faExpandAlt} />
        </Link>
      </div>
      <Link to="/directory/sentiment/locations" className={Styles['sentiment-analysis-map']}>
        <SentimentWorldChart
          isLocationsTab={false}
          onlyData
          selectedMainTimePeriod={selectedFilter}
        />
      </Link>
      <div className={Styles['sentiment-analysis-content']}>
        <Preloader loading={initialLoading} centered hasBigScreenDesign />
        <SentimentAnalysisSection
          title="Locations"
          negativeItems={topics.locations.negative}
          positiveItems={topics.locations.positive}
          loading={loading}
          isGapSupport={isGapSupport}
          selectedFilter={selectedFilter}
        />
        <SentimentAnalysisSection
          title="Topic Classes"
          noData={topicClasses.noData}
          negativeItems={topicClasses.negative}
          positiveItems={topicClasses.positive}
          loading={loading}
          isGapSupport={isGapSupport}
          selectedFilter={selectedFilter}
        >
          <ItemsScrollBar
            data={topicClassesTabs}
            onClick={handleTopicTabClick}
            withUnderline
            scrollToSelected
            skipTabGap
          />
        </SentimentAnalysisSection>
        <SentimentAnalysisSection
          title="Sector"
          negativeItems={topics.sectors.negative}
          positiveItems={topics.sectors.positive}
          loading={loading}
          isGapSupport={isGapSupport}
          selectedFilter={selectedFilter}
        />
      </div>
      <div className={Styles['sentiment-analysis-footer']}>
        <Link
          to="/news/sentiment"
          className={Styles['see-all']}
        >
          See All
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </div>
    </div>
  );
};

export default memo(SentimentAnalysis, () => true);
