import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';

import SimplifiedLayoutButtons from '../../StoryFilters/SimplifiedLayoutButton';
import SwitchViewButtons from '../../StoryFilters/SwitchViewButtons';

import Styles from './styles.module.scss';

export const SimplifiedLayoutFilters = ({
  onSimplifyFilterChange = () => { },
  onUserNewsfeedView = () => { },
}) => {
  const onChange = useCallback((value) => {
    onSimplifyFilterChange(value);
  }, [onSimplifyFilterChange]);

  const userNewsfeedViewWeb = useSelector(({ storyFilters }) => (
    storyFilters.user_newsfeed_view_web
  ));
  const userCompactViewWeb = useSelector(({ storyFilters }) => (
    storyFilters.user_newsfeed_compactview_web
  ));
  const userNewsfeedView = useSelector(({ storyFilters }) => storyFilters.user_newsfeed_view);
  const userCompactView = useSelector(({ storyFilters }) => storyFilters.user_newsfeed_compactview);
  const userAdvancedView = useSelector(({ storyFilters }) => (
    storyFilters.user_newsfeed_advanced_view_web
  ));

  const currentUserNewsfeedView = isMobileOnly ? userNewsfeedView : userNewsfeedViewWeb;
  const currentUserCompactView = isMobileOnly ? userCompactView : userCompactViewWeb;

  return (
    <>
      <div className={Styles['simplified-layout-filters']}>
        <div className={Styles['simplified-layout-filters-label']}>
          Layout options:
        </div>
        <SimplifiedLayoutButtons
          status={userAdvancedView}
          onSimplifiedViewTabsClick={onChange}
          isHomePage
          theme="blue"
        />
        <SwitchViewButtons
          isSearch={false}
          isHomePage
          theme="blue"
          onUserNewsfeedView={onUserNewsfeedView}
          userNewsfeedView={currentUserNewsfeedView}
          userCompactView={currentUserCompactView}
        />
      </div>
    </>
  );
};

export default memo(SimplifiedLayoutFilters);
