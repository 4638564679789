import {
  memo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';

import { useSelector } from 'react-redux';
import EntitiesSvc from '../../../../services/dbServices/EntitiesSvc';

import Scrollable from '../../../Shared/Scrollable';
import DcscPoweredByWithTooltip from '../../../Shared/DcscPoweredByWithTooltip';

import { convertPermalinkToPath } from '../../../../helpers/urlHelpers';

import Styles from './styles.module.scss';
import SentimentTag from '../../../SEO_pages/TopicsSentimentAnalysis/SentimentTags/SentimentTag';
import Preloader from '../../../Preloader';
import { getTrendingBarAssetQuery } from '../../../../helpers/watchlistHelperFunctions';
import SectorsSvc from '../../../../services/SectorsSvc';
import TopicsSvc from '../../../../services/TopicsSvc';
import { SECTORS_PATH_ITEM } from '../../../../data/directory/constants';
import SentimentModal from '../../../SEO_pages/TopicsSentimentAnalysis/SentimentTags/SentimentModal';

const cx = classNames.bind(Styles);

export const SentimentAnalysisSection = ({
  title,
  noData,
  positiveItems,
  negativeItems,
  loading,
  isGapSupport,
  isSectors,
  selectedFilter,
  children,
}) => {
  const history = useHistory();
  const sentimentContentRef = useRef(null);

  const promoHeight = useSelector(({ promobar }) => (
    promobar?.height
  ));

  const width = useSelector(({ common }) => (
    common?.width
  ));

  const [selectedTopic, setSelectedTopic] = useState(null);
  const [topicQuery, setQuery] = useState('');

  const handleTopicRedirect = async (selectedTopic) => {
    let topic;
    let topicURL = '';
    if (isSectors) {
      topic = await SectorsSvc.getCategory(selectedTopic.entity_id);
      topicURL = `/news/directory/${SECTORS_PATH_ITEM}/${topic.subindustry.industry.sector.slug}/${topic.subindustry.industry.slug}/${topic.subindustry.slug}/${topic.slug}`;
    } else if (selectedTopic.entity_type.toLowerCase() === 'country') {
      topicURL = await EntitiesSvc.getEntityPermalink({
        entityId: selectedTopic.entity_id,
        entityType: selectedTopic.entity_type,
      }).then(({ permalink }) => {
        if (permalink) {
          return convertPermalinkToPath(permalink);
        }
      });
    } else {
      topic = await TopicsSvc.getTopicById(selectedTopic.entity_id);
      const assetClassSlug = topic?.asset_class?.slug;
      const topicSlug = topic?.slug;

      topicURL = `/news/directory/${assetClassSlug}/${topicSlug}`;
    }

    history.push(topicURL);
  };

  const handleTopicSelect = (e, topic) => {
    if (width <= 1024) {
      handleTopicRedirect(topic);
      return;
    }

    const offsetY = e.currentTarget.getBoundingClientRect().top;
    const pageY = window.pageYOffset + offsetY;

    const pageX = e.currentTarget.getBoundingClientRect().left;
    const currentWidth = e.currentTarget.clientWidth / 2;

    let x;
    let y = pageY + 50 - promoHeight;
    let arrowLeft = 'calc(50% - 9px)';
    if (width >= 2800) {
      y = pageY + 100 - promoHeight;
      if (pageX + 920 + currentWidth <= window.innerWidth) {
        if (pageX - 920 + currentWidth > 40) {
          x = pageX - 900 + currentWidth;
        } else {
          x = 40;
          arrowLeft = `${pageX + currentWidth - 58}px`;
        }
      } else {
        x = window.innerWidth - 1840;
        arrowLeft = `${pageX - x - 18 + currentWidth}px`;
      }
    } else if (pageX + 460 + currentWidth <= window.innerWidth) {
      if (pageX - 460 + currentWidth > 20) {
        x = pageX - 450 + currentWidth;
      } else {
        x = 20;
        arrowLeft = `${pageX + currentWidth - 29}px`;
      }
    } else {
      x = window.innerWidth - 920;
      arrowLeft = `${pageX - x - 9 + currentWidth}px`;
    }

    const query = getTrendingBarAssetQuery({
      id: topic.entity_id,
      name: topic.entity_name,
    }, topic.entity_type);

    setSelectedTopic({
      ...topic,
      x,
      y,
      arrowLeft,
    });
    setQuery(query);
  };

  const handleModalClose = () => {
    setSelectedTopic(null);
    setQuery('');
  };

  return (
    <div className={Styles['sentiment-analysis-section']} ref={sentimentContentRef}>
      <div className={Styles['sentiment-analysis-section-header']}>
        <div className={Styles['sentiment-analysis-section-title']}>
          {title}
        </div>
        {title.toLowerCase() === 'sector' && (
          <DcscPoweredByWithTooltip className={Styles.dcsc} />
        )}
      </div>
      {children && (
        <div className={Styles['sentiment-analysis-section-tabs']}>
          {children}
        </div>
      )}
      {(title === 'Topic Classes' && loading) ? <Preloader loading static /> : (
        <>
          <Scrollable arrowStyle="compact-centered">
            <div className={cx('sentiment-analysis-section-content', { loading, no_gap: !isGapSupport })}>
              {noData ? (
                <div className={Styles['sentiment-analysis-section-content-noData']}>
                  No data found for this time period. Try a longer period.
                </div>
              ) : (
                [positiveItems, negativeItems].map((items, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} className={Styles['sentiment-analysis-section-items']}>
                    {items.length ? items.map((item) => (
                      <SentimentTag
                        key={item.entity_id}
                        item={item}
                        selectedTopic={selectedTopic}
                        handleTopicSelect={handleTopicSelect}
                        width={width}
                        promoHeight={promoHeight}
                        isInfoItemLabel
                        hideTooltip
                      />
                    )) : (
                      !loading && (
                        <div className={Styles['sentiment-analysis-section-content-noData']}>
                          This time period doesn&apos;t look so good. No&nbsp;
                          {index === 0 ? 'positive' : 'negative'}
                          &nbsp;sentiment to show.
                        </div>
                      )
                    )}
                  </div>
                ))
              )}
            </div>
          </Scrollable>
          {selectedTopic && (
            <SentimentModal
              activePeriod={selectedFilter}
              slugType={selectedTopic.entity_type}
              topicId={selectedTopic.entity_id}
              topicName={selectedTopic.entity_name}
              x={selectedTopic.x}
              y={selectedTopic.y}
              arrowLeft={selectedTopic.arrowLeft}
              sentimentContentRef={sentimentContentRef}
              handleModalClose={handleModalClose}
              query={topicQuery}
              parentSentiment={selectedTopic.sentiment}
              isTopicClass={selectedTopic.entity_type.toLowerCase() === 'assetclass'}
              isInfoLabel
              isTopic
            />
          )}
        </>
      )}
    </div>
  );
};

SentimentAnalysisSection.propTypes = {
  title: PropTypes.string.isRequired,
};

export default memo(SentimentAnalysisSection);
