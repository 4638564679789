import premium from '../../assets/homepage/main_feature_carousel/premium.png';
import premiumMob from '../../assets/homepage/main_feature_carousel/premium-mob.png';
import sentiments from '../../assets/homepage/main_feature_carousel/sentiments.png';
import sentimentsMob from '../../assets/homepage/main_feature_carousel/sentiments-mob.png';
import internalContent from '../../assets/homepage/main_feature_carousel/internal-content.png';
import internalContentMob from '../../assets/homepage/main_feature_carousel/internal-content-mob.png';
import keyStories from '../../assets/homepage/main_feature_carousel/key-stories.png';
import keyStoriesMob from '../../assets/homepage/main_feature_carousel/key-stories-mob.png';
import allTool from '../../assets/homepage/main_feature_carousel/all-tool.png';
import allToolMob from '../../assets/homepage/main_feature_carousel/all-tool-mob.png';
import trendingContent from '../../assets/homepage/main_feature_carousel/trending-content.png';
import trendingContentMob from '../../assets/homepage/main_feature_carousel/trending-content-mob.png';
import internationalStories from '../../assets/homepage/main_feature_carousel/international-stories.png';
import internationalStoriesMob from '../../assets/homepage/main_feature_carousel/international-stories-mob.png';
import leftWave from '../../assets/homepage/main_feature_carousel/background-wave-left.svg';
import rightWave from '../../assets/homepage/main_feature_carousel/background-wave-right.svg';
import leftWaveMob from '../../assets/homepage/main_feature_carousel/background-wave-left-mob.svg';
import rightWaveMob from '../../assets/homepage/main_feature_carousel/background-wave-right-mob.svg';
import leftWaveBig from '../../assets/homepage/main_feature_carousel/background-wave-left-big.svg';
import rightWaveBig from '../../assets/homepage/main_feature_carousel/background-wave-right-big.svg';

export default [
  {
    tabName: 'Premium',
    id: 'premium',
    title: 'Skip the Paywalls. Skip the Ads.',
    subTitle: 'One subscription for select content from 1000+ publications.',
    link: 'https://www.cityfalcon.ai/products/premium-publications',
    backgroundImage: leftWave,
    backgroundImageBig: leftWaveBig,
    backgroundImageMob: leftWaveMob,
    class: 'premium',
    contentImage: premium,
    contentImageMob: premiumMob,
    styles: {
      marginTop: '117px',
      marginRight: '51px',
      width: '404px',
      height: '332px',
      bgWidth: '1060px',
      bgHeight: '237px',
    },
    tabStyles: {
      marginTop: '52px',
      marginRight: '21px',
      width: '318px',
      height: '235px',
      bgWidth: '840px',
      bgHeight: '190px',
    },
    mobStyles: {
      marginTop: '11px',
      marginRight: '20px',
      width: '187px',
      height: '163px',
      bgWidth: '727px',
      bgHeight: '149px',
    },
    bigScreenStyles: {
      marginTop: '243px',
      marginRight: '130px',
      width: '1191px',
      height: '877px',
      bgWidth: '2649px',
      bgHeight: '592px',
    },
  },
  {
    tabName: 'Sentiment',
    id: 'sentiment',
    title: 'Insight into the mood of the market',
    subTitle: 'AI scores individual news and social media content as positive, negative, or neutral sentiment and aggregates scores.',
    link: 'https://www.cityfalcon.ai/features/sentiment',
    backgroundImage: rightWave,
    backgroundImageBig: rightWaveBig,
    backgroundImageMob: rightWaveMob,
    contentImage: sentiments,
    contentImageMob: sentimentsMob,
    class: 'sentiment',
    styles: {
      marginTop: '113px',
      marginRight: '30px',
      width: '439px',
      height: '314px',
      bgWidth: '1060px',
      bgHeight: '237px',
    },
    tabStyles: {
      marginTop: '53px',
      marginRight: '31px',
      width: '307px',
      height: '220px',
      bgWidth: '840px',
      bgHeight: '190px',
    },
    mobStyles: {
      marginTop: '12px',
      marginRight: '10px',
      width: '202px',
      height: '140px',
      bgWidth: '727px',
      bgHeight: '149px',
    },
    bigScreenStyles: {
      marginTop: '258px',
      marginRight: '130px',
      width: '1206px',
      height: '862px',
      bgWidth: '2649px',
      bgHeight: '592px',
    },
  },
  {
    tabName: 'Internal Content',
    id: 'internal-content',
    title: 'Unlock insights in your own data',
    subTitle: 'AI and automation help you index, curate, and understand your organisation’s emails, PDFs, and scanned documents.',
    link: 'https://www.cityfalcon.ai/products/extract-and-structure-insights',
    backgroundImage: leftWave,
    backgroundImageBig: leftWaveBig,
    backgroundImageMob: leftWaveMob,
    contentImage: internalContent,
    contentImageMob: internalContentMob,
    class: 'internal_content',
    styles: {
      marginTop: '136px',
      marginRight: '26px',
      width: '499px',
      height: '280px',
      bgWidth: '1060px',
      bgHeight: '237px',
    },
    tabStyles: {
      marginTop: '93px',
      marginRight: '15px',
      width: '323px',
      height: '181px',
      bgWidth: '840px',
      bgHeight: '190px',
    },
    mobStyles: {
      marginTop: '12px',
      marginRight: '9px',
      width: '237px',
      height: '133px',
      bgWidth: '727px',
      bgHeight: '149px',
    },
    bigScreenStyles: {
      marginTop: '278px',
      marginRight: '130px',
      width: '1310px',
      height: '735px',
      bgWidth: '2649px',
      bgHeight: '592px',
    },
  },
  {
    tabName: 'Key Stories',
    id: 'key-stories',
    title: 'More knowledge in less time',
    subTitle: 'Curated content grouped, ranked, and condensed by AI in real-time to reduce reading and improve your investment decision-making.',
    link: 'https://www.cityfalcon.ai/features/summarisation',
    backgroundImage: rightWave,
    backgroundImageBig: rightWaveBig,
    backgroundImageMob: leftWaveMob,
    contentImage: keyStories,
    contentImageMob: keyStoriesMob,
    class: 'key_stories',
    styles: {
      marginTop: '93px',
      marginRight: '71px',
      width: '367px',
      height: '373px',
      bgWidth: '1060px',
      bgHeight: '237px',
    },
    tabStyles: {
      marginTop: '60px',
      marginRight: '39px',
      width: '220px',
      height: '224px',
      bgWidth: '840px',
      bgHeight: '190px',
    },
    mobStyles: {
      marginTop: '-5px',
      marginRight: '-3px',
      width: '162px',
      height: '141px',
      bgWidth: '727px',
      bgHeight: '149px',
    },
    bigScreenStyles: {
      marginTop: '214px',
      marginRight: '239px',
      width: '916px',
      height: '931px',
      bgWidth: '2649px',
      bgHeight: '592px',
    },
  },
  {
    tabName: 'All-in-one Tool',
    id: 'all-in-one-tool',
    title: 'The Only Due Diligence App You Need',
    subTitle: '300k Topics, Extensive Data, Valuable Analytics.',
    link: 'https://www.cityfalcon.ai/features',
    backgroundImage: leftWave,
    backgroundImageBig: leftWaveBig,
    backgroundImageMob: leftWaveMob,
    contentImage: allTool,
    contentImageMob: allToolMob,
    class: 'all_tool',
    styles: {
      marginTop: '113px',
      marginRight: '30px',
      width: '577px',
      height: '325px',
      bgWidth: '1060px',
      bgHeight: '237px',
    },
    tabStyles: {
      marginTop: '85px',
      marginRight: '19px',
      width: '346px',
      height: '196px',
      bgWidth: '840px',
      bgHeight: '190px',
    },
    mobStyles: {
      marginTop: '17px',
      marginRight: '10px',
      width: '261px',
      height: '141px',
      bgWidth: '727px',
      bgHeight: '149px',
    },
    bigScreenStyles: {
      marginTop: '263px',
      marginRight: '130px',
      width: '1365px',
      height: '768px',
      bgWidth: '2649px',
      bgHeight: '592px',
    },
  },
  {
    tabName: 'Trending Content',
    id: 'trending-content',
    title: 'Never Miss a Breaking Story Again',
    subTitle: 'We track thousands of sources to bring you stories in real-time and identify trends across the globe.',
    link: 'https://www.cityfalcon.ai/news',
    backgroundImage: rightWave,
    backgroundImageBig: rightWaveBig,
    backgroundImageMob: leftWaveMob,
    contentImage: trendingContent,
    contentImageMob: trendingContentMob,
    class: 'trending_content',
    styles: {
      marginTop: '146px',
      marginRight: '30px',
      width: '489px',
      height: '272px',
      bgWidth: '1060px',
      bgHeight: '237px',
    },
    tabStyles: {
      marginTop: '91px',
      marginRight: '27px',
      width: '323px',
      height: '180px',
      bgWidth: '840px',
      bgHeight: '190px',
    },
    mobStyles: {
      marginTop: '12px',
      marginRight: '11px',
      width: '241px',
      height: '135px',
      bgWidth: '727px',
      bgHeight: '149px',
    },
    bigScreenStyles: {
      marginTop: '311px',
      marginRight: '130px',
      width: '1345px',
      height: '747px',
      bgWidth: '2649px',
      bgHeight: '592px',
    },
  },
  {
    tabName: 'International Stories',
    id: 'international-stories',
    title: 'Language is No Longer a Barrier',
    subTitle: 'Content in 50+ languages plus translation available.',
    link: 'https://www.cityfalcon.ai/features/language-coverage',
    backgroundImage: leftWave,
    backgroundImageBig: leftWaveBig,
    backgroundImageMob: leftWaveMob,
    contentImage: internationalStories,
    contentImageMob: internationalStoriesMob,
    class: 'international_stories',
    styles: {
      marginTop: '167px',
      marginRight: '30px',
      width: '547px',
      height: '215px',
      bgWidth: '1060px',
      bgHeight: '237px',
    },
    tabStyles: {
      marginTop: '71px',
      marginRight: '25px',
      width: '359px',
      height: '196px',
      bgWidth: '840px',
      bgHeight: '190px',
    },
    mobStyles: {
      marginTop: '32px',
      marginRight: '11px',
      width: '252px',
      height: '138px',
      bgWidth: '727px',
      bgHeight: '149px',
    },
    bigScreenStyles: {
      marginTop: '425px',
      marginRight: '129px',
      width: '1366px',
      height: '538px',
      bgWidth: '2649px',
      bgHeight: '592px',
    },
  },
];
