export const mainSectionData = {
  content_for_you: {
    title: 'Content for You',
    tooltipText: 'Top content based on all the topics in all of your watchlists.',
  },
  news_by_location: {
    title: 'News by Location',
    tooltipText: 'News filtered by location. Location choices here do not apply to other sections on the home screen.',
  },
  sentiment_analysis: {
    title: 'SENTIMENT ANALYSIS',
    tooltipText: (
      <>
        Sentiment shows how positive and negative a story or topic is.&nbsp;
        <a
          href="https://www.cityfalcon.ai/features/sentiment"
          target="_blank"
          rel="noreferrer noopener"
        >
          Learn more
        </a>
      </>
    ),
  },
  trending_topics: {
    title: 'Trending Topics & Securities',
    tooltipText: (
      <>
        Topics that are experiencing an unusually high level of activity.&nbsp;
        <a
          href="https://www.cityfalcon.ai/help/articles/360002510494"
          target="_blank"
          rel="noreferrer noopener"
        >
          Learn more
        </a>
      </>
    ),
  },
  discover_videos: {
    title: 'Discover the CityFALCON Experience',
  },
  investor_relations: {
    title: 'Investor Relations',
    tooltipText: (
      <>
        Presentations, earnings calls, reports,
        and other important documents that appear
        on investor relations pages of corporate websites.
        <br />
        <a
          href="https://www.cityfalcon.ai/features/investor-relations"
          target="_blank"
          rel="noreferrer noopener"
        >
          Learn more
        </a>
      </>
    ),
  },
  company_esg_reports: {
    title: 'Company ESG Reports',
    tooltipText: 'Documents and reports from companies on ESG, CSR, and similar topics.',
  },
  filings_by_sources: {
    title: 'Company Filings by Sources',
    tooltipText: (
      <>
        Official regulatory filings from agencies and exchanges.&nbsp;
        <a
          href="https://www.cityfalcon.ai/features/filings"
          target="_blank"
          rel="noreferrer noopener"
        >
          Learn more
        </a>
      </>
    ),
  },
  filings_by_categories: {
    title: 'Company Filings by Categories',
    tooltipText: (
      <>
        Official regulatory filings from agencies and exchanges.&nbsp;
        <a
          href="https://www.cityfalcon.ai/features/filings"
          target="_blank"
          rel="noreferrer noopener"
        >
          Learn more
        </a>
      </>
    ),
  },
  gainers_and_losers: {
    title: 'GAINERS AND LOSERS',
    tooltipText: 'Big movers in various asset classes. Calculations follow industry standards.',
  },
  news_on_charts: {
    title: 'News On Charts',
    tooltipText: (
      <>
        A tool to match prices to news to understand why markets move.&nbsp;
        <a
          href="https://www.cityfalcon.ai/features/news-on-charts"
          target="_blank"
          rel="noreferrer noopener"
        >
          Learn more
        </a>
      </>
    ),
  },
  business_events: {
    title: 'Major Business Events',
    tooltipText: 'News categorised by important event types.',
  },
  news_by_asset_classes: {
    title: 'News by Asset Classes',
    tooltipText: 'News categorised by asset classes.',
  },
};

export const discoverVideosTab = [
  {
    id: 1,
    content: 'Desktop',
    active: true,
    videoLink: 'https://cityfalcon.wistia.com/medias/ajt73zy7sg',
  },
  {
    id: 2,
    content: 'Mobile',
    active: false,
    videoLink: 'https://cityfalcon.wistia.com/medias/mftbajn65v',
  },
  {
    id: 3,
    content: 'Voice',
    active: false,
    videoLink: 'https://cityfalcon.wistia.com/medias/1cyold2f7s',
  },
  {
    id: 4,
    content: 'API',
    active: false,
    videoLink: 'https://cityfalcon.wistia.com/medias/icax7uk29d',
  },
];

export default mainSectionData;
