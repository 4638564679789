import classNames from 'classnames/bind';
import Slider from 'react-slick';
import {
  useEffect, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import Styles from './styles.module.scss';
import mainFeaturesCarousel from '../../../data/homepage/mainFeaturesCarousel';
import NextArrow from './Arrows/NextArrow';
import PrevArrow from './Arrows/PrevArrow';
import CarouselNavigator from './CarouselNavigator';

const cx = classNames.bind(Styles);

const MainFeaturesCarousel = () => {
  const [activeTileIndex, setActiveTileIndex] = useState(0);
  const [navTiles, setNavTiles] = useState();

  const mainWidth = useSelector(({ common }) => (
    common.width
  ));
  const isChatbotOpened = useSelector(({ chatbot }) => (
    chatbot.isChatbotOpened
  ));

  let sliderTiles = useRef();

  useEffect(() => {
    setNavTiles(sliderTiles);
  }, []);

  const getTilesElementsProperty = (mainFeature) => {
    let styles;
    let background = mainFeature.backgroundImage;
    let image = mainFeature.contentImage;
    if (mainWidth <= 1024 && mainWidth > 767) {
      styles = mainFeature.tabStyles;
    } else if (mainWidth <= 767) {
      styles = mainFeature.mobStyles;
      background = mainFeature.backgroundImageMob;
      image = mainFeature.contentImageMob;
    } else if (mainWidth >= 2800) {
      styles = mainFeature.bigScreenStyles;
      background = mainFeature.backgroundImageBig;
    } else {
      styles = mainFeature.styles;
    }

    return { styles, background, image };
  };

  const beforeSliderChange = (_, next) => {
    setTimeout(() => {
      setActiveTileIndex(next);
    }, 400);
  };

  const onClickTabHandler = (tabId) => {
    const activeIndex = mainFeaturesCarousel.findIndex((mainFeature) => mainFeature.id === tabId);
    sliderTiles.slickGoTo(activeIndex);
    setActiveTileIndex(activeIndex);
  };

  const tileSettings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    speed: 400,
    centerPadding: '683px',
    centerMode: true,
    beforeChange: beforeSliderChange,
    nextArrow: <NextArrow
      currentSlideIndex={activeTileIndex}
    />,
    prevArrow: <PrevArrow
      currentSlideIndex={activeTileIndex}
    />,
    responsive: [
      {
        breakpoint: 2799,
        settings: {
          centerPadding: '152px',
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '50px',
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: '20px',
        },
      },
    ],
  };

  const prevTilesIndex = activeTileIndex - 1;
  const nextTilesIndex = activeTileIndex + 1;

  return (
    <div className={cx('main_carousel_container')}>
      <div className={cx('main_carousel_slider')}>
        <Slider
          ref={(slider) => { sliderTiles = slider; }}
          asNavFor={navTiles}
          {...tileSettings}
          className={cx('tiles_slider', { chatbot: isChatbotOpened })}
        >
          {mainFeaturesCarousel.map((mainFeature, index) => {
            const { styles, background, image } = getTilesElementsProperty(mainFeature);

            return (
              <div
                key={mainFeature.tabName}
                className={cx('tiles_slider_item',
                  {
                    active: activeTileIndex === index,
                    prev: prevTilesIndex === index,
                    next: nextTilesIndex === index,
                  })}
              >
                <div className={cx('tiles_slider_item_container', {
                  active: activeTileIndex === index,
                })}
                >
                  <div className={cx('tiles_slider_item_info')}>
                    <div className={cx('tiles_slider_item_title',
                      `tiles_slider_item_title_${mainFeature.class}`)}
                    >
                      {mainFeature.title}
                    </div>
                    <div className={cx('tiles_slider_item_subtitle',
                      `tiles_slider_item_subtitle_${mainFeature.class}`)}
                    >
                      {mainFeature.subTitle}
                    </div>
                    <div className={cx('tiles_slider_item_button')}>
                      <a
                        href={mainFeature.link}
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                  <div
                    className={cx('tiles_slider_item_image')}
                    style={styles}
                  >
                    <img
                      src={image}
                      width={styles.width}
                      height={styles.height}
                      alt="Content"
                      loading="lazy"
                    />
                  </div>
                  <div className={cx('tiles_slider_item_background')}>
                    <img
                      src={background}
                      width={styles.bgWidth}
                      height={styles.bgHeight}
                      alt="Wave"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <CarouselNavigator
        activeTileIndex={activeTileIndex}
        changeTab={onClickTabHandler}
        mainWidth={mainWidth}
      />
    </div>
  );
};

export default MainFeaturesCarousel;
