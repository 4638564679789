import {
  memo,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';

import loadable from '../../../utils/loadable';
import { mainSectionData } from '../../../data/homepage/homePageData';

import useSizes from '../../../hooks/sizes';

import Tooltip from '../../Tooltip';
import SectionDataContext from './SectionDataContext';
import HomePeriodFilters from '../HomePeriodFilters';
import PlayVideoComponent from '../../PlayVideoComponent';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const VideoModal = loadable(() => import('../../Modals/VideoModal'));

export const SectionWrapper = ({
  type,
  title: propTitle,
  tooltipText: propTooltipText,
  tooltipClass,
  videoUrl,
  seeMoreUrl,
  filters,
  withFilters,
  className,
  defaultVisible,
  minHeight,
  renderTooltipOutside,
  children,
  handleSectionTitle,
  tooltipClassName,
}) => {
  const { title, tooltipText } = mainSectionData[type] || {
    title: propTitle,
    tooltipText: propTooltipText,
  };
  const [storyPeriodFilter, setStoryPeriodFilter] = useState(null);
  const [visible, setVisible] = useState(defaultVisible);
  const [videoVisible, setVideoVisible] = useState(false);
  const [sectionInView, setInView] = useState(false);
  const sectionRef = useRef();

  const tooltipContentStyles = useMemo(() => ({
    zIndex: '150',
    position: 'fixed',
    transform: 'translate(-44%, -112%)',
  }), []);

  const promoHeight = useSelector(({ promobar }) => (
    promobar.height
  ));
  const isChatbotOpened = useSelector(({ chatbot }) => (
    chatbot.isChatbotOpened
  ));
  const { width } = useSizes();

  const { ref: inViewRef, inView } = useInView({
    rootMargin: width > 1024 ? '600px' : '300px',
  });

  const onScroll = () => {
    if (!handleSectionTitle) return;
    const sectionBoundry = sectionRef.current.getBoundingClientRect();
    const headerHeight = width > 767 ? 80 : 50;
    const allowedPromoHeight = width > 767 ? promoHeight : 0;
    const topSpace = headerHeight + 20 + allowedPromoHeight;
    const insideSection = sectionBoundry.top <= topSpace
      && (sectionBoundry.bottom + 20) >= topSpace;
    if (type === 'business_events' || type === 'news_by_asset_classes') {
      if (!insideSection) {
        handleSectionTitle(type, insideSection);
      }
    } else {
      handleSectionTitle(type, insideSection);
    }
  };

  useEffect(() => {
    if (inView) {
      setInView(true);
      window.addEventListener('scroll', onScroll, { passive: true });
      onScroll();
    } else {
      if (handleSectionTitle) {
        handleSectionTitle(type, false);
      }
      window.removeEventListener('scroll', onScroll);
    }

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [inView]);

  const contextData = useMemo(() => ({
    selectedFilter: storyPeriodFilter,
    setVisible,
    sectionInView,
    isChatbotOpened: isChatbotOpened && (width > 1024),
  }), [storyPeriodFilter, sectionInView, width, isChatbotOpened]);

  const content = (
    <SectionDataContext.Provider value={contextData}>
      {sectionInView ? children : null}
    </SectionDataContext.Provider>
  );

  const setRefs = useCallback(
    (node) => {
      sectionRef.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  return (
    <section className={`${cx('section-wrapper')} ${className}`} ref={setRefs}>
      <div
        hidden={!visible}
        className={cx('section-wrapper-container')}
      >
        <div className={cx('section-wrapper-header')}>
          <div className={cx('section-wrapper-header-left')}>
            <div className={cx('section-wrapper-header-title')}>
              {title}
            </div>
            {tooltipText !== null && sectionInView && (
              <Tooltip
                className={cx('section-wrapper-header-tooltip', {
                  [tooltipClass]: true,
                }, tooltipClassName)}
                renderOutside={renderTooltipOutside}
                contentStyle={renderTooltipOutside ? tooltipContentStyles : {}}
                hideWithOverflow={renderTooltipOutside}
                hasBigScreenDesign
              >
                {tooltipText}
              </Tooltip>
            )}
            {type === 'news_on_charts' && (
              <PlayVideoComponent
                videoLink="https://cityfalcon.wistia.com/medias/ss2alfe5pj"
                videoLinkMobile="https://cityfalcon.wistia.com/medias/bg126ct1i0"
                additionalStyles={cx('chart_play_button')}
                tooltipAdditionalContentStyle={cx('chart_play_button_tooltip')}
              />
            )}
          </div>
          <div className={cx('section-wrapper-header-right')}>
            {withFilters && (
              <HomePeriodFilters
                onStoryFilterChange={setStoryPeriodFilter}
                initialFilters={filters}
              />
            )}
            {seeMoreUrl !== null && (
              <Link className={cx('see-more-url')} to={seeMoreUrl}>
                See More
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            )}
          </div>
        </div>
        <div
          className={cx('section-wrapper-content', { 'chatbot-opened': isChatbotOpened })}
          style={minHeight !== null ? { minHeight } : {}}
        >
          {content}
        </div>
      </div>
      {videoUrl && (
        <VideoModal
          show={videoVisible}
          closeModal={() => setVideoVisible(false)}
          source={videoUrl}
        />
      )}
    </section>
  );
};

SectionWrapper.propTypes = {
  title: PropTypes.node,
  tooltipText: PropTypes.node,
  videoUrl: PropTypes.string,
  seeMoreUrl: PropTypes.string,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
      active: PropTypes.bool,
    }),
  ),
  withFilters: PropTypes.bool,
  className: PropTypes.string,
  defaultVisible: PropTypes.bool,
  minHeight: PropTypes.number,
  renderTooltipOutside: PropTypes.bool,
};

SectionWrapper.defaultProps = {
  title: '',
  tooltipText: null,
  videoUrl: null,
  seeMoreUrl: null,
  filters: null,
  withFilters: false,
  className: '',
  defaultVisible: true,
  minHeight: null,
  renderTooltipOutside: false,
};

export default memo(SectionWrapper);
