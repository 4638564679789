import { memo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export const PromoSection = ({
  title = '',
  subtitle = '',
  buttonText = '',
  buttonUrl = '',
  className = '',
  iconData,
  iconDataMobile,
  pricing,
  useAnchor,
  additionalStyles,
}) => (
  <section className={cx('promo-section', { className, pricing })}>
    <div className={Styles['promo-section-left']}>
      <div className={Styles.text_content}>
        <h3 className={Styles.title}>{title}</h3>
        {subtitle && (
          <p className={Styles.subtitle}>
            {subtitle}
          </p>
        )}
      </div>
      {useAnchor ? (
        <a
          href={buttonUrl}
          className={cx('button', additionalStyles)}
        >
          {buttonText}
        </a>
      ) : (
        <Link
          to={buttonUrl}
          className={cx('button', additionalStyles)}
        >
          {buttonText}
        </Link>
      )}
    </div>
    <img
      className={Styles.icon}
      src={iconData.src}
      width={iconData.width}
      height={iconData.height}
      alt="promo icon"
      loading="lazy"
    />
    <img
      className={Styles['icon-mobile']}
      src={iconDataMobile.src}
      width={iconDataMobile.width}
      height={iconDataMobile.height}
      alt="promo icon"
      loading="lazy"
    />
  </section>
);

export default memo(PromoSection);
