import { createRef, Component } from 'react';
import classNames from 'classnames/bind';
import autoBind from 'react-autobind';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export default class ChangeViewButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkStatus: '',
    };
    autoBind(this);

    this.radio = createRef();
  }

  componentDidMount() {
    const { status } = this.props;

    this.setState({
      checkStatus: status,
    });
  }

  componentDidUpdate(prevProps) {
    const { status } = this.props;
    if (status !== prevProps.status) {
      this.setState({
        checkStatus: status,
      });
    }
  }

  toggleCheck() {
    const { onChange, title, disabled } = this.props;

    // temporaty code, because now bookmarks have only rows view
    if (disabled) return;

    this.setState({
      checkStatus: this.radio.current.checked,
    }, () => {
      if (onChange) onChange(title.toLowerCase());
    });
  }

  render() {
    const {
      rbi,
      id = 'toggle_today_summary',
      title,
      name,
      viewClass,
      hasBigScreenDesign,
      disabled,
    } = this.props;
    const { checkStatus } = this.state;

    return (
      <div
        className={`${cx('change_view_button', { rbi, big_screen: hasBigScreenDesign })} ${cx(viewClass, { disabled: (viewClass !== 'list-view' && disabled) })}`}
      >
        <input
          type="radio"
          id={id}
          name={name}
          onChange={this.toggleCheck}
          ref={this.radio}
          checked={checkStatus === title.toLowerCase()}
        />
        <label
          htmlFor={id}
          onClick={this.toggleCheck}
        >
          {title}
        </label>
      </div>
    );
  }
}

ChangeViewButton.defaultProps = {
  className: '',
};
