import { memo, useCallback, useState } from 'react';

import ItemsScrollBar from '../../Shared/ItemsScrollBar';

import { newsOrder, sentimentOptions } from '../../../data/homepage/sectionsFilters';

import Styles from './styles.module.scss';

export const SimplifiedLayoutOptions = ({
  onOrderChange = () => {},
  onSentimentChange = () => {},
  className = '',
}) => {
  const [orders, setOrders] = useState(newsOrder);
  const [sentiments, setSentiments] = useState(sentimentOptions);

  const setOrder = useCallback((id) => {
    setOrders((currentOrders) => currentOrders.map((order) => {
      if (order.active) {
        order.active = false;
      }

      if (order.id === id) {
        onOrderChange(order.id);
        order.active = true;
      }

      return order;
    }));
  }, [onOrderChange]);

  const setSentiment = useCallback((id) => {
    setSentiments((currentSentiments) => currentSentiments.map((sentiment) => {
      if (sentiment.active) {
        sentiment.active = false;
      }

      if (sentiment.id === id) {
        onSentimentChange(sentiment.id);
        sentiment.active = true;
      }

      return sentiment;
    }));
  }, []);

  return (
    <div className={`${Styles['simplified-layout-options']} ${className}`}>
      <ItemsScrollBar
        data={orders}
        withUnderline
        className={Styles['simplified-layout-options-group']}
        itemClassName={Styles['simplified-layout-options-orders-item']}
        onClick={setOrder}
        dragging={false}
      />
      <ItemsScrollBar
        data={sentiments}
        withUnderline
        className={Styles['simplified-layout-options-group']}
        itemClassName={Styles['simplified-layout-options-sentiments-item']}
        onClick={setSentiment}
        dragging={false}
      />
    </div>
  );
};

export default memo(SimplifiedLayoutOptions);
