import { memo } from 'react';
import classNames from 'classnames/bind';

import DcscPoweredBy from '../DcscPoweredBy';

import Styles from './styles.module.scss';
import { Tooltip } from '../../Tooltip';

const cx = classNames.bind(Styles);

const DcscPoweredByWithTooltip = ({
  className = '',
  url = 'https://dcsc.ai',
}) => (
  <div className={cx('dcsc-powered-by-with-tooltip', className)}>
    <a
      aria-label="dcsc logo"
      href={url}
      target="_blank"
      rel="noreferrer"
      className={Styles.wrappper}
    >
      <DcscPoweredBy />
    </a>
    <Tooltip
      newIcon
      contentStyle={{ left: 0 }}
      dynamicPosition
      className={Styles.tooltip}
      tooltipClassName={Styles['tooltip-content']}
    >
      Our DCSC platform focuses on sectors and helps you explore and
      better understand sectors, companies, and the economy.
      Sign into DCSC using your CityFALCON account.&nbsp;
      <a href="https://dcsc.ai" target="_blank" rel="noreferrer">Learn more</a>
    </Tooltip>
  </div>
);

export default memo(DcscPoweredByWithTooltip);
