import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { bindActionCreators } from 'redux';
import { isMobileOnly } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';

import ChangeLayoutButton from '../../../MainTrending/TrendingFilters/TrendingPersonalisationFilter/ChangeLayoutButton';
import ChangeViewButton from '../../../MainTrending/TrendingFilters/TrendingPersonalisationFilter/ChangeViewButton';

import { updateUserNewsFeedView } from '../../../../helpers/storyFilterHelpers';

import * as storyFiltersActions from '../../../../actions/storyFilters.action';
import * as storyActions from '../../../../actions/stories.actions';
import * as watchlistActions from '../../../../actions/watchlistActions';
import * as searchActions from '../../../../actions/searchActions';

import Styles from './styles.module.scss';
import PlayVideoComponent from '../../../PlayVideoComponent';

const cx = classNames.bind(Styles);

export const LayoutFiltersMobile = ({
  isSimpleLayout,
  onClose,
}) => {
  const [checkStatus, setCheckStatus] = useState('');
  const { userToken: token } = useSelector(({ watchlistReducer }) => (
    watchlistReducer
  ));
  const {
    userNewsfeedViewWeb, userCompactViewWeb,
    userNewsfeedView, userCompactView,
  } = useSelector(({ storyFilters }) => ({
    userNewsfeedViewWeb: storyFilters.user_newsfeed_view_web,
    userCompactViewWeb: storyFilters.user_newsfeed_compactview_web,
    userNewsfeedView: storyFilters.user_newsfeed_view,
    userCompactView: storyFilters.user_newsfeed_compactview,
  }));
  const dispatch = useDispatch();
  const actions = useMemo(() => (
    bindActionCreators({
      ...storyFiltersActions,
      ...storyActions,
      ...watchlistActions,
      ...searchActions,
    }, dispatch)
  ), [dispatch]);

  const onUserNewsfeedViewChange = useCallback((status) => {
    setCheckStatus(status);
    updateUserNewsFeedView({
      status,
      actions,
      token,
    });
  }, [actions, token]);

  const currentUserNewsfeedView = isMobileOnly ? userNewsfeedView : userNewsfeedViewWeb;
  const currentUserCompactView = isMobileOnly ? userCompactView : userCompactViewWeb;
  useEffect(() => {
    setCheckStatus(currentUserCompactView ? 'compact' : currentUserNewsfeedView);
  }, [currentUserNewsfeedView, currentUserCompactView]);

  return (
    <div className={Styles['layout-filters-mobile']}>
      <FontAwesomeIcon className={cx('close')} icon={faXmark} onClick={onClose} />
      <h4 className={Styles.title}>
        News feed layout
        <PlayVideoComponent
          videoLink="https://cityfalcon.wistia.com/medias/ktxo5yh7gp"
          videoLinkMobile="https://cityfalcon.wistia.com/medias/ktxo5yh7gp"
          additionalStyles={cx('mobile_layout_filters_play_button')}
        />
      </h4>
      <p className={Styles.subtitle}>
        Layout view:
      </p>
      <div className={Styles['simplify-layout']}>
        <ChangeLayoutButton
          id="simple"
          title="Simple"
          status={isSimpleLayout}
          name="simplifiedLayout"
          viewClass="simple-layout"
        />
        <ChangeLayoutButton
          id="advanced"
          title="Advanced"
          status={isSimpleLayout}
          name="simplifiedLayout"
          viewClass="advanced-layout"
        />
      </div>
      <div className={Styles['news-feed']}>
        <ChangeViewButton
          id="rows__switch"
          status={checkStatus}
          rbi={false}
          title="Rows"
          onChange={onUserNewsfeedViewChange}
          name="userNewsfeedView"
          viewClass="list-view"
          hasBigScreenDesign={false}
        />
        <ChangeViewButton
          id="compact__switch"
          status={checkStatus}
          rbi={false}
          title="Compact"
          onChange={onUserNewsfeedViewChange}
          name="userNewsfeedView"
          viewClass="compact-view"
          hasBigScreenDesign={false}
        />
        <ChangeViewButton
          id="tiles__switch"
          status={checkStatus}
          rbi={false}
          title="Tiles"
          onChange={onUserNewsfeedViewChange}
          name="userNewsfeedView"
          viewClass="tiles-view"
          hasBigScreenDesign={false}
        />
      </div>
    </div>
  );
};

export default memo(LayoutFiltersMobile);
