import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useStoryFilters = (filters) => {
  const [storyPeriodFilters, setStoryPeriodFilters] = useState(filters);

  const {
    permissions: {
      allowed_search_periods: allowedSearchPeriods,
    },
  } = useSelector((state) => (
    state.subscriptions
  ));

  useEffect(() => {
    if (
      allowedSearchPeriods.includes('year1')
      && storyPeriodFilters.length === 3
      && filters.length > 3
    ) {
      setStoryPeriodFilters(filters);
    } else if (
      !allowedSearchPeriods.includes('year1')
      && storyPeriodFilters.length === 4
    ) {
      setStoryPeriodFilters(filters.slice(0, 3));
    }
  }, [allowedSearchPeriods, storyPeriodFilters, filters]);

  return [storyPeriodFilters, setStoryPeriodFilters];
};

export default useStoryFilters;
