import { useState } from 'react';
// hook for create fadding efects for horizontall scroll.
const useScroll = () => {
  // set scroll left when user scroll to left
  const [scrollLeft, setScrollLeft] = useState(0);
  // width of scroll area
  const [scrollWidth, setScrollWidth] = useState(0);
  // visible for user scroll area
  const [clientWidth, setClientWidth] = useState(0);

  // handler for onScroll function
  const onScrollHandler = (event) => {
    setScrollLeft(event.target.scrollLeft);
    setScrollWidth(event.target.scrollWidth);
    setClientWidth(event.target.clientWidth);
  };

  // check a position for scroll container
  // set RIGHT, LEFT, OR BETWEEN. It is depence of scroll position
  function getPosition() {
    const isRight = clientWidth === scrollWidth - scrollLeft;
    const isLeft = scrollLeft === 0;
    const isBetween = scrollLeft > 0 && clientWidth < scrollWidth - scrollLeft;

    let left = 'none';
    let right = 'none';

    if (isLeft) {
      right = 'block';
    } else if (isBetween) {
      right = 'block';
      left = 'block';
    } else if (isRight) {
      left = 'block';
    }

    return { left, right };
  }

  return { position: getPosition(), onScrollHandler };
};

export default useScroll;
