import { memo } from 'react';
import { useSelector } from 'react-redux';

import NewsByLocationSection from './NewsByLocationSection';
import Scrollable from '../../Shared/Scrollable';

import { GLOBAL, LOCAL } from '../../../data/homepage/newsByLocationTypes';

import Styles from './styles.module.scss';

export const NewsByLocations = () => {
  const { width } = useSelector(({ common }) => (
    common
  ));

  return (
    <div className={Styles['news-by-locations']}>
      <Scrollable
        wrapperClassName={Styles['news-sections']}
        disabled={width > 1023}
        hideArrows
        carousel
      >
        <NewsByLocationSection
          title="Local"
          tooltipContent="News based on the location(s) you've selected."
          type={LOCAL}
        />
        <NewsByLocationSection
          title="Global"
          tooltipContent="All news around the world regardless of your selected location(s)."
          type={GLOBAL}
        />
      </Scrollable>
    </div>
  );
};

export default memo(NewsByLocations, () => true);
