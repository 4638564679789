import { memo } from 'react';
import classNames from 'classnames/bind';

import ButtonComponent from '../../../Buttons/ButtonComponent';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export const PromoSectionCard = ({
  iconData,
  title,
  mainButton,
  secondaryButton,
  big,
  type,
  isReload,
}) => (
  <div className={cx('promo-section-card', { big, [type]: true })}>
    <div className={Styles['promo-section-card-text']}>
      <h4 className={Styles.title}>
        {title}
      </h4>
      <div className={Styles.buttons}>
        {secondaryButton && (
          <a className={Styles['secondary-button']} href={secondaryButton.url}>
            {secondaryButton.text}
          </a>
        )}
        <ButtonComponent
          nomargin
          withoutReload={!isReload}
          path={mainButton.url}
          value={mainButton.text}
          short
          shortAlways
        />
      </div>
    </div>
    <img
      src={iconData.src}
      width={iconData.width}
      height={iconData.height}
      alt="icon"
      loading="lazy"
      className={Styles.icon}
    />
  </div>
);

export default memo(PromoSectionCard);
