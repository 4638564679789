import classNames from 'classnames/bind';
import { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const NextArrow = ({ onClick, currentSlideIndex }) => {
  const isHidden = currentSlideIndex === 6;

  return (
    <div className={cx('arrow_fade_next', { hide: isHidden })}>
      <div
        onClick={onClick}
        className={cx('arrow_button', 'arrow_button_next')}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </div>
  );
};

export default memo(NextArrow);
