import {
  memo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight as faChevronRightRegular } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faChevronLeft as faChevronLeftLight } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight as faChevronRightLight } from '@fortawesome/pro-light-svg-icons/faChevronRight';

import loadable from '../../../utils/loadable';

import { discoverVideosTab } from '../../../data/homepage/homePageData';

import useSizes from '../../../hooks/sizes';

import Tabs from './Tabs';

import Styles from './styles.module.scss';

const WistiaPlayer = loadable(() => import('../../Shared/WistiaPlayer'));

const cx = classNames.bind(Styles);

const videoObj = {
  video_1: false,
  video_2: false,
  video_3: false,
  video_4: false,
};

const Arrow = memo(({
  direction, onClick, currentSlide, slideCount,
}) => {
  const isHidden = direction === 'left' ? !currentSlide : currentSlide + 1 === slideCount;

  return (
    <div className={cx('arrow', direction, { hide: isHidden })}>
      <div className={cx('icon-wrapper')} onClick={onClick}>
        <FontAwesomeIcon icon={direction === 'left' ? faChevronLeftLight : faChevronRightLight} />
      </div>
    </div>
  );
});

const DiscoverVideosSection = ({ handleSectionTitle }) => {
  const [videosTab, setVideosTab] = useState(discoverVideosTab);
  const [startVideoPlay, setStartVideoPlay] = useState(videoObj);
  const sliderRef = useRef();
  const sectionRef = useRef();

  const { width } = useSizes();

  const { ref: inViewRef, inView } = useInView({
    rootMargin: width > 1024 ? '600px' : '300px',
  });

  const promoHeight = useSelector(({ promobar }) => (
    promobar.height
  ));
  const onScroll = () => {
    const sectionBoundry = sectionRef.current.getBoundingClientRect();
    const headerHeight = width > 767 ? 80 : 50;
    const allowedPromoHeight = width > 767 ? promoHeight : 0;
    const topSpace = headerHeight + 20 + allowedPromoHeight;
    const insideSection = sectionBoundry.top <= topSpace
      && (sectionBoundry.bottom + 20) >= topSpace;
    handleSectionTitle('discover_videos', insideSection);
  };

  const pauseStartVideo = (name) => {
    setStartVideoPlay({
      ...videoObj,
      ...(name ? ({ [name]: false }) : {}),
    });
  };

  const setActiveVideo = useCallback((id, excludeSlider) => {
    pauseStartVideo();
    setVideosTab((currentTopics) => currentTopics.map((type) => {
      if (type.active) {
        type.active = false;
      }

      if (type.id === id) {
        type.active = true;
      }

      return type;
    }));
    if (!excludeSlider) sliderRef.current.slickGoTo(id - 1);
  }, []);

  useEffect(() => {
    if (inView) {
      window.addEventListener('scroll', onScroll, { passive: true });
      onScroll();
    } else {
      handleSectionTitle('discover_videos', false);
      window.removeEventListener('scroll', onScroll);
    }
    setActiveVideo(1, true);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [inView]);

  const playStartVideo = (name) => {
    setStartVideoPlay({
      ...videoObj,
      [name]: true,
    });
  };
  const beforeSlideChange = (current, next) => {
    setActiveVideo(next + 1, true);
  };

  const settings = {
    infinite: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '440px',
    nextArrow: <Arrow direction="right" />,
    prevArrow: <Arrow direction="left" />,
    beforeChange: beforeSlideChange,
    responsive: [
      {
        breakpoint: 2799,
        settings: {
          centerPadding: '220px',
        },
      },
      {
        breakpoint: 1023,
        settings: {
          centerPadding: '126px',
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: '48px',
        },
      },
    ],
  };

  const setRefs = useCallback(
    (node) => {
      sectionRef.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  return (
    <div className={Styles['discovery-videos']} ref={setRefs}>
      <div className={Styles['discovery-videos-wrapper']}>
        <div className={Styles['discovery-videos-title']}>
          Discover the CityFALCON Experience
        </div>
        <div className={Styles['discovery-videos-subtitle']}>
          Quick video guides to our data, analytics, insights, and platform.
          <Link to="/help-videos" className={Styles['discovery-videos-tabs-see']}>
            See More videos
            <FontAwesomeIcon icon={faChevronRightRegular} />
          </Link>
        </div>
        <div className={Styles['discovery-videos-tabs-wrapper']}>
          <Tabs
            data={videosTab}
            setActiveVideo={setActiveVideo}
          />
          <Link to="/help-videos" className={Styles['discovery-videos-tabs-see']}>
            See More videos
            <FontAwesomeIcon icon={faChevronRightRegular} />
          </Link>
        </div>
        <div className={Styles['discovery-videos-slider-wrapper']}>
          <Slider
            className={Styles['discovery-videos-slider']}
            {...settings}
            ref={sliderRef}
          >
            {videosTab.map(({ id, videoLink }) => (
              <div
                key={id}
                className={Styles['discovery-videos-slider-outer']}
              >
                <div className={cx('discovery-videos-slider-video', { full_video: id !== 2 })}>
                  {inView && (
                    <WistiaPlayer
                      className={Styles['slider-video-player']}
                      url={videoLink}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                      }}
                      controls
                      playing={startVideoPlay[`video_${id}`]}
                      onPlay={() => playStartVideo(`video_${id}`)}
                      onPause={() => pauseStartVideo(`video_${id}`)}
                      config={{
                        options: isMobile ? { playsinline: false } : {},
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default DiscoverVideosSection;
