import { memo } from 'react';

import Scrollable from '../../Shared/Scrollable';
import PromoSectionCard from './PromoSectionCard';

import promoSectionLarge from '../../../data/homepage/promoSectionLarge';

import Styles from './styles.module.scss';

export const PromoSectionLarge = () => (
  <Scrollable
    className={Styles['promo-section-wrapper']}
    wrapperClassName={Styles['promo-section']}
    carousel
    hideArrows
  >
    {promoSectionLarge.map((data) => (
      <PromoSectionCard
        key={data.id}
        {...data}
      />
    ))}
  </Scrollable>
);

export default memo(PromoSectionLarge, () => true);
