import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import trustedLogos from '../../../data/imagesTrusted';
import { checkFlexGapSupport } from '../../../helpers/helpers';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export const TrustedBySection = () => {
  const mainWidth = useSelector(({ common }) => (
    common.width
  ));
  const [isGapSupport, setIsGapSupport] = useState(true);

  const getImgSize = (trustedLogo) => {
    if (mainWidth >= 2800) {
      return {
        width: trustedLogo.bigSizes.width,
        height: trustedLogo.bigSizes.height,
      };
    }
    if (mainWidth > 1023) {
      return {
        width: trustedLogo.width,
        height: trustedLogo.height,
      };
    }
    if (mainWidth <= 1023 && mainWidth > 767) {
      return {
        width: trustedLogo.tabSizes.width,
        height: trustedLogo.tabSizes.height,
      };
    }
    return {
      width: trustedLogo.mobileSizes.width,
      height: trustedLogo.mobileSizes.height,
    };
  };

  useEffect(() => {
    setIsGapSupport(checkFlexGapSupport());
  }, []);

  return (
    <div className={cx('trusted_by', { no_gap: !isGapSupport })}>
      <div className={cx('trusted_by_main_block')}>
        <h2 className={Styles.trusted_by_title}>Trusted By</h2>
        <div className={Styles.trusted_by_description}>
          <p className={Styles.trusted_by_description_text}>
            Financial institutions, financial companies, and 300k+ users
          </p>
        </div>
      </div>
      <div className={Styles.trusted_by_logos}>
        {trustedLogos.map((trustedLogo) => {
          const { width, height } = getImgSize(trustedLogo);

          return (
            <div
              key={trustedLogo.id}
              className={Styles.trusted_by_logo}
            >
              <img
                width={width}
                height={height}
                src={trustedLogo.logo}
                alt={trustedLogo.alt}
                loading="lazy"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(TrustedBySection, () => true);
