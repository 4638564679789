const productsAndDevices = [
  {
    id: 1,
    width: 30,
    height: 30,
    bigWidth: 60,
    bigHeight: 60,
    class: 'ios',
    newTab: true,
    withRedirect: true,
    name: 'iOS',
    path: 'https://apps.apple.com/us/app/cityfalcon/id1148560018',
  },
  {
    id: 2,
    width: 26,
    height: 30,
    bigWidth: 53,
    bigHeight: 61,
    class: 'android',
    newTab: true,
    withRedirect: true,
    name: 'Android',
    path: 'https://play.google.com/store/apps/details?id=com.cityfalcon',
  },
  {
    id: 3,
    width: 15,
    height: 45,
    bigWidth: 30,
    bigHeight: 90,
    class: 'amazon-alexa',
    newTab: false,
    withRedirect: true,
    name: 'Amazon Alexa',
    path: '/news/alexa',
  },
  {
    id: 4,
    width: 20,
    height: 29,
    bigWidth: 40,
    bigHeight: 58,
    class: 'google-home',
    newTab: false,
    withRedirect: true,
    name: 'Google Home',
    path: '/news/google_home',
  },
  {
    id: 5,
    width: 38,
    height: 30,
    bigWidth: 75,
    bigHeight: 60,
    class: 'api',
    newTab: false,
    withRedirect: true,
    name: 'API',
    path: '/products/api/financial-news',
  },
  {
    id: 6,
    width: 30,
    height: 30,
    bigWidth: 60,
    bigHeight: 60,
    class: 'chat-bot',
    newTab: false,
    withRedirect: true,
    name: 'ChatBot',
    comingSoon: true,
  },
];

export default productsAndDevices;
