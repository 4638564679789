import bitcoin from '../assets/homepage/topics_sector_locations/bitcoin.svg';
import apple from '../assets/homepage/topics_sector_locations/apple.svg';
import oil from '../assets/homepage/topics_sector_locations/oil.svg';
import microchip from '../assets/homepage/topics_sector_locations/microchip.svg';
import usFlag from '../assets/homepage/topics_sector_locations/us-flag.svg';

export default [
  {
    logo: bitcoin,
    name: 'Bitcoin',
    width: 11.5,
    height: 15,
    bigSizes: {
      width: 23,
      height: 30,
    },
    label: 'Cryptocurrency',
    link: '/news/directory/cryptocurrencies/bitcoin-btc/news',
  },
  {
    logo: apple,
    name: 'Apple',
    width: 13,
    height: 14,
    bigSizes: {
      width: 24,
      height: 28,
    },
    label: 'Stock',
    link: '/news/directory/stocks/apple-inc-aapl',
  },
  {
    logo: oil,
    name: 'Oil',
    width: 10,
    height: 14,
    bigSizes: {
      width: 20,
      height: 28,
    },
    label: 'Commodity',
    link: '/news/directory/commodities/oil',
  },
  {
    logo: microchip,
    name: 'Technology',
    width: 16,
    height: 16,
    bigSizes: {
      width: 32,
      height: 32,
    },
    label: 'Sector',
    link: '/news/directory/dcsc-sectors/level1/technology',
  },
  {
    logo: usFlag,
    name: 'United States',
    width: 14,
    height: 14,
    bigSizes: {
      width: 28,
      height: 28,
    },
    label: 'Location',
    link: '/news/directory/locations/country/united-states-of-america',
  },
];
