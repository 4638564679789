import {
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { isMobileOnly } from 'react-device-detect';
import isEqual from 'lodash-es/isEqual';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';

import SectionDataContext from '../../SectionWrapper/SectionDataContext';
import GeneralFiltersContext from '../../GeneralFiltersContext';

import { getLocationStoryFilters, getStoryParams } from '../../../../helpers/storyHelpers';
import { compareFilters } from '../../../../helpers/helpers';
import loadable from '../../../../utils/loadable';

import StoriesSvc from '../../../../services/StoriesSvc';

import Tooltip from '../../../Tooltip';
import StoryCard from '../../../TrendingStories/StoryCard';
import Preloader from '../../../Preloader';
import SimplifiedLayoutOptions from '../../SimplifiedLayoutOptions';
import Summarisation from '../../../Summarisation';

import { GLOBAL, LOCAL } from '../../../../data/homepage/newsByLocationTypes';
import timeFilterMapping from '../../../../data/homepage/timeFilterMapping';

import Styles from './styles.module.scss';
import useSizes from '../../../../hooks/sizes';
import { freezeStories } from '../../../../actions/stories.actions';

const Masonry = loadable(() => import('react-masonry-component'));

const cx = classNames.bind(Styles);

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export const NewsByLocationSection = ({
  title,
  tooltipContent,
  type,
}) => {
  const [storesData, setStoriesData] = useState({
    stories: [],
    summaryRequestMethod: null,
    loading: true,
  });
  const [order, setOrder] = useState('top');
  const [sentiment, setSentiment] = useState('all');

  const { selectedFilter } = useContext(SectionDataContext);
  const {
    selectedLocations, allLocationsSelected,
  } = useContext(GeneralFiltersContext);

  const masonry = useRef(null);
  const prevStoryFilters = useRef(null);

  const { user } = useSelector((state) => (
    state.userPreferencesReducer
  ));
  const storyFilters = useSelector((state) => (
    state.storyFilters
  ));
  const {
    permissions: {
      earliest_search_date: earliestSearchDate,
    },
    permissionsInitialized,
  } = useSelector((state) => (
    state.subscriptions
  ));
  const { userToken: token } = useSelector(({ watchlistReducer }) => (
    watchlistReducer
  ));

  const dispatch = useDispatch();

  const simplifyFilter = storyFilters.user_newsfeed_advanced_view_web;

  const prevOrder = usePrevious(order);
  const prevSentiment = usePrevious(sentiment);
  const prevSelectedLocations = usePrevious(selectedLocations);
  const prevSelectedFilter = usePrevious(selectedFilter);
  useEffect(() => () => {
    prevStoryFilters.current = storyFilters;
  }, [storyFilters]);

  const deps = [
    storyFilters,
    selectedFilter, type,
    permissionsInitialized,
    order, sentiment, token,
    // initialLocationsSelected,
  ];
  if (type === LOCAL) {
    deps.push(selectedLocations);
  }

  let mounted = true;
  useEffect(() => {
    mounted = true;

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (
      !permissionsInitialized
      || !storyFilters
      || (
        prevStoryFilters.current
        && compareFilters(prevStoryFilters.current, storyFilters)
        && order === prevOrder && sentiment === prevSentiment
        && (type === LOCAL && isEqual(selectedLocations, prevSelectedLocations))
        && selectedFilter === prevSelectedFilter
      )
    ) {
      return;
    }

    setStoriesData(({ stories, summaryRequestMethod }) => ({
      stories,
      summaryRequestMethod,
      loading: true,
    }));
    const params = getStoryParams({
      filters: {
        ...storyFilters,
        limit: 20,
        selected_time_filter: 'predefined',
        only_with_images: !token ? true : undefined,
        order_by: order,
        time_filter: timeFilterMapping.get(selectedFilter),
        group_similar_stories: true,
        time_period: undefined,
        start_datetime: undefined,
        end_datetime: undefined,
        sentiment_gte: undefined,
        sentiment_lte: undefined,
        ...(storyFilters.languages === '' && { all_languages: true }),
        ...(
          type === LOCAL && selectedLocations.length && !allLocationsSelected
          && getLocationStoryFilters(selectedLocations)
        ),
      },
      selectedSentimentTab: sentiment,
      isSimpleLayout: false,
    });
    StoriesSvc.getStories(
      params,
      earliestSearchDate,
      { callCurationActions: true },
    ).then(({
      stories,
    }) => {
      if (!mounted) {
        return;
      }

      setStoriesData({
        stories,
        summaryRequestMethod: (limit = 2, options = {}) => (
          StoriesSvc
            .getStories({
              ...params,
              only_summaries: true,
              number_of_summaries: limit,
              ...options,
            }, earliestSearchDate)
        ),
        loading: false,
      });
    });
  }, deps);

  const { width } = useSizes();

  const userNewsfeedView = isMobileOnly ? (
    storyFilters.user_newsfeed_view
  ) : (
    storyFilters.user_newsfeed_view_web
  );
  const userNewsfeedCompactView = isMobileOnly ? (
    storyFilters.user_newsfeed_compactview
  ) : (
    storyFilters.user_newsfeed_compactview_web
  );
  const isTilesView = userNewsfeedView === 'tiles' && !userNewsfeedCompactView;
  const initialLoading = storesData.loading && !storesData.stories?.length;
  const storiesLimit = userNewsfeedCompactView ? 10 : 6;

  const onHideClicked = (storyId) => {
    const updatedCards = storesData?.stories?.map((item) => {
      if (item.id === storyId) {
        return {
          ...item,
          hide: true,
        };
      }
      return item;
    });

    setStoriesData({
      stories: updatedCards.filter((item) => item.id !== storyId),
      summaryRequestMethod: null,
      loading: false,
    });
  };

  const onFreezeStories = () => {
    dispatch(freezeStories());
  };

  const onBookmarkClicked = (data) => {
    const { id: storyId, bookmarked } = data;

    const updatedCards = storesData?.stories?.map((item) => {
      if (item.id === storyId) {
        return {
          ...item,
          bookmarked,
        };
      }
      return item;
    });

    setStoriesData({
      stories: updatedCards,
      summaryRequestMethod: null,
      loading: false,
    });
  };

  const onLikeClicked = (data) => {
    const { id: storyId, liked, disliked } = data;
    const updatedCards = storesData?.stories?.map((item) => {
      if (item.id === storyId) {
        return {
          ...item,
          liked,
          disliked,
        };
      }
      return item;
    });

    setStoriesData({
      stories: updatedCards,
      summaryRequestMethod: null,
      loading: false,
    });
  };

  const StoriesWrapper = isTilesView ? Masonry : 'div';
  const storiesWrapperProps = {
    className: cx({
      tiles_wrapper: isTilesView,
      without_tiles: !isTilesView,
    }),
    ...(isTilesView && {
      disableImagesLoaded: true,
      options: {
        gutter: width < 2800 ? 10 : 20,
        percentPosition: true,
        resize: false,
        isResizeBound: false,
        transitionDuration: 0,
      },
      ref: (c) => { masonry.current ||= c.masonry; },
    }),
  };

  return (
    <div className={cx('news-by-location-section', type?.toLowerCase?.())}>
      <div className={Styles['news-by-location-section-header']}>
        <h5 className={Styles['news-by-location-section-title']}>{title}</h5>
        <Tooltip
          className={Styles['news-by-location-section-tooltip']}
          tooltipClassName={Styles['news-by-location-section-tooltip-content']}
          renderOutside={width <= 1023}
          contentStyle={width <= 1023 ? {
            transform: 'translate(-69px, -64px)',
          } : {}}
          hideWithOverflow={width <= 1023}
          skipSmall={width <= 1023}
          hasBigScreenDesign
        >
          {tooltipContent}
        </Tooltip>
      </div>
      <div
        className={Styles['news-by-location-section-content']}
        style={(
          !storesData.loading && !storesData.stories.length ? {
            minHeight: 'auto',
          } : {}
        )}
      >
        {simplifyFilter && (
          <SimplifiedLayoutOptions
            onOrderChange={setOrder}
            onSentimentChange={setSentiment}
          />
        )}
        <Summarisation
          storiesEndpoint={storesData.summaryRequestMethod}
          isTilesView={isTilesView}
          isHidden={!storesData.stories.length}
          className={Styles.summarisation}
          isHomePage
          hasBigScreenDesign
        />
        <Preloader
          loading={initialLoading}
          static
          centered
          transformYCentered100={title === 'Local'}
          hasBigScreenDesign
        />

        <div
          className={cx('stories-list', {
            loading: !initialLoading && storesData.loading,
            tiles: isTilesView,
          })}
        >
          {storesData.stories.length ? (
            <StoriesWrapper {...storiesWrapperProps}>
              {storesData.stories.slice(0, storiesLimit).map((story) => (
                <StoryCard
                  key={story.id}
                  user={user}
                  type={story.type}
                  story={story}
                  onHide={onHideClicked}
                  onFreezeStories={onFreezeStories}
                  onLikeChanged={onLikeClicked}
                  onBookmarkClick={onBookmarkClicked}
                  piwikEnabled={false}
                  loading={false}
                  masonryObj={masonry}
                  skipSimilarStories
                  homepageStories
                  hasBigScreenDesign
                />
              ))}
            </StoriesWrapper>
          ) : (
            !storesData.loading && (
              <div className="pl-2">
                No content was found that meets your criteria
              </div>
            )
          )}
        </div>
        {type === GLOBAL && (
          <div className={Styles['news-by-location-section-footer']}>
            <Link
              to="/news/sentiment"
              className={Styles['see-all']}
            >
              See More
              <FontAwesomeIcon icon={faChevronRight} />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(NewsByLocationSection);
