import {
  memo, useCallback, useContext, useEffect, useRef,
} from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { InView } from 'react-intersection-observer';

import ButtonComponent from '../../Buttons/ButtonComponent';
import SearchbarInner from '../../SearchbarComponents/SearchbarInner';

import greetingSection from '../../../assets/homepage/greeting-section.svg';

import { setShowHPHeaderSearchbar } from '../../../actions/header.actions';

import withComponentName from '../../../decorators/withComponentName';

import PiwikContext from '../../../services/piwikContext';

import Styles from './styles.module.scss';
import imagesTopicsSectorsLocations from '../../../data/imagesTopicsSectorsLocations';
import TopicsSectorsLocations from './TopicsSectorsLocations';
import useScroll from '../../../hooks/useScroll';

const cx = classNames.bind(Styles);

export const GreetingSection = () => {
  const inputHolder = useRef();
  const { position, onScrollHandler } = useScroll();
  const { left, right } = position;
  const searchBarActive = useSelector(({ searchReducers }) => (
    searchReducers.searchBarActive
  ));
  const width = useSelector(({ common }) => (
    common.width
  ));
  const promoHeight = useSelector(({ promobar }) => (
    promobar?.height
  ));
  const isChatbotOpened = useSelector(({ chatbot }) => (
    chatbot.isChatbotOpened
  ));
  const dispatch = useDispatch();

  const { piwikEnabled } = useContext(PiwikContext);

  const onSectionVisibilityChange = useCallback((inView) => {
    dispatch(setShowHPHeaderSearchbar(!inView));
  }, [dispatch]);

  useEffect(() => (
    () => onSectionVisibilityChange(false)
  ), [onSectionVisibilityChange]);

  const sendHomePageActions = (action) => {
    if (piwikEnabled) _paq.push(['trackEvent', 'Home page', action]);
  };

  const getImgSize = (imageTopicsSectorsLocation) => {
    if (width >= 2800) {
      return {
        width: imageTopicsSectorsLocation.bigSizes.width,
        height: imageTopicsSectorsLocation.bigSizes.height,
      };
    }

    return {
      width: imageTopicsSectorsLocation.width,
      height: imageTopicsSectorsLocation.height,
    };
  };

  return (
    <div className={cx('greeting_wrapper', { with_promo: promoHeight }, { chatbot: isChatbotOpened })}>
      <div className={Styles.greeting_wrapper_section}>
        <div className={Styles.description}>
          <div className={Styles.description_left}>
            <div>
              <h1>
                AI-Powered Intelligence
              </h1>
              <p>
                For Financial Markets and Business
                <br />
                <span>
                  via voice or text in&nbsp;
                  <a href="https://www.cityfalcon.ai/features/language-coverage">
                    50+ languages
                  </a>
                </span>
              </p>
            </div>
            <div className={Styles.greeting_buttons}>
              <Link
                to="/learn-more"
                className={Styles.greeting_buttons_learn_more}
              >
                Learn More
              </Link>
              <ButtonComponent
                withoutReload
                value="Personalise"
                checkEvent={() => sendHomePageActions('Get started (main)')}
                green
                path="/news/onboarding/watchlist"
                className={Styles.greeting_buttons_personalize}
              />
            </div>
          </div>
        </div>
        <img
          className={Styles.greeting_section_image}
          loading="lazy"
          src={greetingSection}
          alt=""
        />
      </div>
      <div className={Styles.footer}>
        <InView
          as="div"
          className={cx({ 'searchbar-active': searchBarActive })}
          style={searchBarActive && width > 767 ? { marginTop: `${promoHeight}px` } : {}}
          onChange={onSectionVisibilityChange}
        >
          <div ref={inputHolder}>
            <SearchbarInner
              searchStyles={[
                'homepage-searchbar',
                'fixed_searchbar',
                'multiple_slide_search',
              ]}
              oneItem
              searchbarType="homeSearchbar"
              piwikEnabled={piwikEnabled}
              hasBigScreenDesign
            />
          </div>
        </InView>
      </div>
      <div className={Styles.topics_sectors_locations_container}>
        <div style={{ display: left }} className={Styles['left-gradient']} />
        <div style={{ display: right }} className={Styles['right-gradient']} />
        <div
          onScroll={onScrollHandler}
          className={Styles.topics_sectors_locations}
        >
          {
            imagesTopicsSectorsLocations.map((item) => {
              const { width, height } = getImgSize(item);
              return (
                <TopicsSectorsLocations
                  logo={item.logo}
                  name={item.name}
                  width={width}
                  height={height}
                  label={item.label}
                  link={item.link}
                  key={item.name}
                />
              );
            })
            }
        </div>
      </div>
    </div>
  );
};

export default memo(withComponentName(GreetingSection));
