import { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';

import { mainSectionData } from '../../../data/homepage/homePageData';

import { getBrowserScrollTop } from '../../../helpers/commonHelpers';

import Tooltip from '../../Tooltip';

import Styles from '../styles.module.scss';

const cx = classNames.bind(Styles);

const HeaderSections = (props) => {
  const {
    generalFiltersVisible,
    onFilterClick, promobarHeight, sectionTitle,
    generalFiltersOpened, user,
  } = props;
  const sectionTextHolderRef = useRef();
  const sectionTextRef = useRef();
  const tooltipRef = useRef();

  const { width } = useSelector(({ common }) => (
    common
  ));
  const isCuratorPlan = useSelector(({ subscriptions }) => (
    subscriptions.permissions.user.premium_plan_keys.includes('curator')
  ));
  const { tooltipText } = mainSectionData[sectionTitle.type] || { };
  const { title } = (sectionTitle || {}).title
    ? sectionTitle : mainSectionData[sectionTitle.type] || { };

  const toggleTransitionWithTimeout = () => {
    sectionTextHolderRef.current.classList.remove(Styles.visible);
    setTimeout(() => {
      requestAnimationFrame(() => {
        // We are manually adding new content and adding class again to node
        sectionTextRef.current.innerHTML = title;
        sectionTextHolderRef.current.classList.add(Styles.visible);
      });
    }, 125);
  };
  useEffect(() => {
    if (sectionTitle.active) {
      toggleTransitionWithTimeout();
    } else {
      tooltipRef?.current?.hideTooltip();
    }
  }, [sectionTitle.type, sectionTitle.active, sectionTitle.title]);

  const allowedPromoHeight = width > 767 ? promobarHeight : 0;

  const isCurator = Boolean(user?.is_curator) || isCuratorPlan;

  return (
    <>
      <div
        className={cx('home-page-section-title', { visible: sectionTitle.active })}
        style={{
          transform: `translateY(${allowedPromoHeight}px)`,
        }}
      >
        <div
          className={cx('home-page-section-slide', { visible: true })}
          ref={sectionTextHolderRef}
        >
          <div
            className={cx('home-page-section-title-text')}
            ref={sectionTextRef}
          />
          {tooltipText && (
            <Tooltip
              className={cx('home-page-section-title-tooltip')}
              ref={tooltipRef}
            >
              {tooltipText}
            </Tooltip>
          )}
        </div>
      </div>
      {!isCurator && (
        <div
          className={cx('filters-expand-btn', {
            visible: (
              !generalFiltersVisible
              && !generalFiltersOpened
              && getBrowserScrollTop() > 200 + 334
            ),
          })}
          onClick={onFilterClick}
          style={{
            transform: `translateY(${allowedPromoHeight + (sectionTitle.active && width < 1024 ? 38 : 0)}px)`,
          }}
        >
          <div className={Styles.message}>
            General filters and settings
          </div>
          <FontAwesomeIcon icon={faFilter} />
        </div>
      )}
    </>
  );
};

export default HeaderSections;
