import { memo } from 'react';
import classNames from 'classnames/bind';

import dcscLogo from '../../../assets/dcsc-logo.svg';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const DcscLogo = ({ className = '' }) => (
  <div className={cx('dcsc-logo', className)}>
    <img className={Styles.logo} src={dcscLogo} alt="dcsc logo" />
    <span className={Styles['logo-text']}>DCSC</span>
  </div>
);

export default memo(DcscLogo);
