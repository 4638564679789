import { memo } from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import LinkChecker from '../../Shared/LinkChecker';

import productsAndDevices from '../../../data/homepage/productsAndDevices';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const ProductsAndDevices = ({ isOpenedChatbotSmallWidth }) => {
  const width = useSelector(({ common }) => (
    common.width
  ));
  return (
    <div className={cx('products_devices_container', { tablet_view: isOpenedChatbotSmallWidth })}>
      <div className={cx('products_devices_description')}>
        <div className={cx('products_devices_title')}>Products</div>
        <div className={cx('products_devices_subtitle')}>
          Reimagine financial content consumption.
          Via text and voice platforms.
        </div>
      </div>
      <div className={cx('products_devices_items')}>
        {productsAndDevices.map((productOrDevice) => (
          productOrDevice.withRedirect ? (
            <LinkChecker
              key={productOrDevice.id}
              linkUrl={productOrDevice.path}
              target={productOrDevice.newTab ? '_blank' : '_self'}
              rel="noreferrer"
              className={cx('products_devices_item', productOrDevice.class)}
            >
              <span
                style={width >= 2800 ? {
                  width: `${productOrDevice.bigWidth}px`, height: `${productOrDevice.bigHeight}px`,
                } : {
                  width: `${productOrDevice.width}px`, height: `${productOrDevice.height}px`,
                }}
                className={cx('icon', productOrDevice.class)}
              />
              <span className={cx('name')}>{productOrDevice.name}</span>
              {
                productOrDevice?.comingSoon && <div className={cx('coming_soon')}>Coming Soon</div>
              }
            </LinkChecker>
          ) : (
            <Link
              key={productOrDevice.id}
              to={productOrDevice.path}
              className={cx('products_devices_item')}
              key={productOrDevice.id}
            >
              <span
                style={width >= 2800 ? {
                  width: `${productOrDevice.bigWidth}px`, height: `${productOrDevice.bigHeight}px`,
                } : {
                  width: `${productOrDevice.width}px`, height: `${productOrDevice.height}px`,
                }}
                className={cx('icon', productOrDevice.class)}
              />
              <span className={cx('name')}>{productOrDevice.name}</span>
            </Link>
          )
        ))}
      </div>
    </div>
  );
};

export default memo(ProductsAndDevices);
