import { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import withComponentName from '../../../../../decorators/withComponentName';
import StorageSvc from '../../../../../services/StorageSvc';
import { toggleUserSimplifiedView } from '../../../../../actions/storyFilters.action';
import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const ChangeLayoutButton = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({ watchlistReducer }) => (
    watchlistReducer.userToken
  ));
  const {
    id, name, title,
    onSimplifiedViewTabsClick,
    viewClass, status, bookmarksPage,
  } = props;
  const [checkStatus, setCheckStatus] = useState('');

  const toggleCheck = (status) => {
    if (bookmarksPage) return;
    onSimplifiedViewTabsClick?.(status === 'advanced');

    setCheckStatus(
      status,
    );
    dispatch(toggleUserSimplifiedView(status === 'advanced', token));
    const isAdvancedLayout = status === 'advanced';
    StorageSvc.setItem(
      'userSimplifiedView',
      JSON.stringify(isAdvancedLayout),
    );
  };

  useEffect(() => {
    setCheckStatus(status ? 'advanced' : 'simple');
  }, [status]);

  return (
    <div
      className={`${cx('change_layout_button')} ${cx(viewClass, { 'simple-layout-bookmarked': (viewClass === 'simple-layout' && bookmarksPage) })}`}
    >
      <input
        type="radio"
        id={id}
        name={name}
        onChange={() => toggleCheck(title.toLowerCase())}
        checked={checkStatus === title.toLowerCase()}
      />
      <label
        htmlFor={id}
        onChange={() => toggleCheck(title.toLowerCase())}
      >
        {title}
      </label>
    </div>
  );
};

export default withComponentName(ChangeLayoutButton);
