import classNames from 'classnames/bind';

import Styles from './styles.module.scss';

import CFLink from '../../../Shared/CFLink';

const cx = classNames.bind(Styles);

const TopicsSectorsLocations = ({
  logo,
  name,
  width,
  height,
  label,
  link,
}) => (
  <CFLink
    to={link}
    className={cx('topics-sectors-locations')}
  >
    <div className={cx('topics-sectors-locations-image')}>
      <img loading="lazy" src={logo} alt={name} width={width} height={height} />
    </div>
    <div className={cx('topics-sectors-locations-content')}>
      <div className={cx('topics-sectors-locations-title')}>{name}</div>
      <div className={cx('topics-sectors-locations-label')}>{label}</div>
    </div>
  </CFLink>
);

export default TopicsSectorsLocations;
