import {
  memo,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames/bind';

// HOCs
import withMetatag from '../../decorators/metadataDecorators/metatagsTitleDecorator';

// helpers
import loadableBase from '../../utils/loadable';

// hooks
import useSizes from '../../hooks/sizes';

// components
import HeaderSections from './HeaderSections';
import SectionWrapper from './SectionWrapper';
import GreetingSection from './GreetingSection';
import TrustedBySection from './TrustedBySection';
import GeneralFiltersSection from './GeneralFiltersSection';
import NewsByLocations from './NewsByLocations';
import SentimentAnalysis from './SentimentAnalysis';
import PromoSection from './PromoSection';
import DiscoverVideosSection from './DiscoverVideosSection';
import PromoSectionLarge from './PromoSectionLarge';
import MainFeaturesCarousel from './MainFeaturesCarousel';
import Preloader from '../Preloader';

import GeneralFiltersContext from './GeneralFiltersContext';

// constants
import { trendingTopicsFilter } from '../../data/homepage/sectionsFilters';
import { promoSectionIntegratedApi, promoSectionPricing } from '../../data/homepage/promoSection';
import { DIRECTORIES_PATH, TOPIC_CLASS_PATH } from '../../data/directory/constants';
import { setShowHPHeaderBoxShadow } from '../../actions/header.actions';

// styles
import Styles from './styles.module.scss';
import ProductsAndDevices from './ProductsAndDevices';

const cx = classNames.bind(Styles);

const loadable = (fn) => (
  loadableBase(fn, {
    fallback: (
      <div style={{ height: 'calc(100% - 1.5em)', display: 'flex', justifyContent: 'center' }}>
        <Preloader loading transform />
      </div>
    ),
  })
);

const trandingLoadable = (fn) => (
  loadableBase(fn, {
    fallback: (
      <div style={{ height: 'calc(100% - 1.5em)', display: 'flex', justifyContent: 'center' }}>
        <Preloader loading static />
      </div>
    ),
  })
);

const TopNews = loadable(() => import('./TopNews'));
const ContentForYouSection = loadable(() => import('./ContentForYouSection'));
const TrendingTopics = trandingLoadable(() => import('./TrendingTopics'));
const GainersAndLosers = loadable(() => import('./GainersAndLosers'));
const NewsByAssetClasses = loadable(() => import('./NewsByAssetClasses'));
const NewsByEvents = loadable(() => import('./NewsByEvents'));
const InvestorRelations = loadable(() => import('./InvestorRelations'));
const FilingsWrapper = loadable(() => import('./Filings/FilingsWrapper'));
const ESGReports = loadable(() => import('./ESGReports'));
const NewsOnCharts = loadable(() => import('./NewsOnCharts'));

export const HomePage = () => {
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [allLocationsSelected, setAllLocationsSelected] = useState(false);
  // general filters options
  const [generalFiltersVisible, setGeneralFiltersVisible] = useState(true);
  const [generalFiltersOpened, setGeneralFiltersOpened] = useState(false);
  const [sectionTitle, setSectionTitle] = useState({
    type: '',
    active: false,
    title: '',
  });
  const sectionTitleRef = useRef();

  const { width } = useSizes();

  const { userToken: token } = useSelector(({ watchlistReducer }) => (
    watchlistReducer
  ));

  const promobarHeight = useSelector(({ promobar }) => (
    promobar.height
  ));
  const openedSearchbarTypesList = useSelector(({ searchReducers }) => (
    searchReducers.openedSearchbarType
  ));

  const searchBarActive = useSelector(({ searchReducers }) => (
    searchReducers.searchBarActive
  ));
  const activeWidgetType = useSelector(({ chatbot }) => (chatbot.activeWidgetType));
  const isChatbotOpened = useSelector(({ chatbot }) => (chatbot.isChatbotOpened));

  const generalFilters = useMemo(() => ({
    selectedLocations,
    allLocationsSelected,
  }), [
    selectedLocations, allLocationsSelected,
  ]);
  const openedSearchbarType = useMemo(() => (
    openedSearchbarTypesList.find((type) => type.opened)?.type
  ), [openedSearchbarTypesList]);

  const onFilterClick = () => {
    setGeneralFiltersOpened(true);
  };

  const dispatch = useDispatch();
  useEffect(() => (() => (
    dispatch(setShowHPHeaderBoxShadow(false))
  )), []);
  const handleSectionTitle = (type, active, title) => {
    if (
      !sectionTitleRef?.current?.type || sectionTitleRef?.current?.type === type || active
    ) {
      sectionTitleRef.current = {
        type,
        active,
        title,
      };
      setSectionTitle({
        type,
        active,
        title,
      });
      dispatch(setShowHPHeaderBoxShadow(active));
    }
  };

  const blurPage = (
    openedSearchbarType === 'headerSearchbar'
    || generalFiltersOpened
  );

  const isOpenedChatbotSmallWidth = (width > 1023 && width < 1500) && isChatbotOpened;

  return (
    <GeneralFiltersContext.Provider value={generalFilters}>
      {blurPage && (
        <div
          className={cx('overlay', { activeSearchbar: searchBarActive })}
          onClick={() => setGeneralFiltersOpened(false)}
        />
      )}
      <div className={cx('home-page-wrapper', { 'chatbot-opened': isChatbotOpened && (activeWidgetType === 'widgetBasic') })}>
        <GeneralFiltersSection
          setSelectedLocations={setSelectedLocations}
          setAllLocationsSelected={setAllLocationsSelected}
          setGeneralFiltersVisible={setGeneralFiltersVisible}
          setGeneralFiltersOpened={setGeneralFiltersOpened}
          generalFiltersOpened={generalFiltersOpened}
          promobarHeight={promobarHeight}
          hiddenFilter
          allLocationsSelected={allLocationsSelected}
          isOpenedChatbotSmallWidth={isOpenedChatbotSmallWidth}
        />
        <div className={cx('home-page',
          {
            blur: blurPage,
            tablet_view: isOpenedChatbotSmallWidth,
          })}
        >
          <HeaderSections
            blurPage={blurPage}
            searchBarActive={searchBarActive}
            setGeneralFiltersOpened={setGeneralFiltersOpened}
            generalFiltersVisible={generalFiltersVisible}
            generalFiltersOpened={generalFiltersOpened}
            onFilterClick={onFilterClick}
            promobarHeight={promobarHeight}
            sectionTitle={sectionTitle}
          />

          <GreetingSection />
          <TrustedBySection />
          <MainFeaturesCarousel />
          <TopNews />
          <GeneralFiltersSection
            setSelectedLocations={setSelectedLocations}
            setAllLocationsSelected={setAllLocationsSelected}
            setGeneralFiltersVisible={setGeneralFiltersVisible}
            setGeneralFiltersOpened={setGeneralFiltersOpened}
            generalFiltersOpened={false}
            promobarHeight={promobarHeight}
            hiddenFilter={false}
            allLocationsSelected={allLocationsSelected}
            isOpenedChatbotSmallWidth={isOpenedChatbotSmallWidth}
          />

          {token && (
            <SectionWrapper
              type="content_for_you"
              handleSectionTitle={handleSectionTitle}
            >
              <ContentForYouSection />
            </SectionWrapper>
          )}

          <div className={Styles['section-responsive']}>
            <div className={Styles['news-by-locations']}>
              <SectionWrapper
                type="news_by_location"
                handleSectionTitle={handleSectionTitle}
                withFilters
              >
                <NewsByLocations />
              </SectionWrapper>
            </div>

            <div className={Styles['sentiments-and-trending']}>
              <SectionWrapper
                type="sentiment_analysis"
                handleSectionTitle={handleSectionTitle}
                tooltipClass={Styles['sentiments-header-tooltip']}
                videoUrl="https://cityfalcon.wistia.com/medias/362922bfs9"
                withFilters
                tooltipClassName="sentimentTooltip"
              >
                <SentimentAnalysis />
              </SectionWrapper>

              <SectionWrapper
                type="trending_topics"
                handleSectionTitle={handleSectionTitle}
                videoUrl="https://cityfalcon.wistia.com/medias/be7nogrmi6#"
                filters={trendingTopicsFilter}
                withFilters
                minHeight={0}
                tooltipClassName="trendingTooltip"
              >
                <TrendingTopics />
              </SectionWrapper>
            </div>
          </div>

          <ProductsAndDevices isOpenedChatbotSmallWidth={isOpenedChatbotSmallWidth} />

          <DiscoverVideosSection handleSectionTitle={handleSectionTitle} />

          <div
            className={cx('section', 'company-data-wrapper', 'mb4')}
          >
            <SectionWrapper
              type="investor_relations"
              handleSectionTitle={handleSectionTitle}
              className={Styles['company-data']}
              minHeight={300}
              tooltipClassName="investorTooltip"
            >
              <InvestorRelations />
            </SectionWrapper>

            <SectionWrapper
              type="company_esg_reports"
              handleSectionTitle={handleSectionTitle}
              className={Styles['company-data']}
              minHeight={300}
            >
              <ESGReports />
            </SectionWrapper>

            <FilingsWrapper
              handleSectionTitle={handleSectionTitle}
            />
          </div>

          <SectionWrapper
            type="gainers_and_losers"
            handleSectionTitle={handleSectionTitle}
            withFilters
            minHeight={0}
          >
            <GainersAndLosers />
          </SectionWrapper>

          <PromoSection {...promoSectionPricing} />

          <SectionWrapper
            type="news_on_charts"
            handleSectionTitle={handleSectionTitle}
            videoUrl="https://cityfalcon.wistia.com/medias/ss2alfe5pj"
            tooltipClassName="newsOnChartTooltip"
          >
            <NewsOnCharts />
          </SectionWrapper>

          <PromoSectionLarge />

          <PromoSection
            {...promoSectionIntegratedApi}
            className={Styles['promo-api']}
          />

          <SectionWrapper
            type="business_events"
            handleSectionTitle={handleSectionTitle}
            videoUrl="https://cityfalcon.wistia.com/medias/t5qc3w8zuw"
            withFilters
            seeMoreUrl={`${DIRECTORIES_PATH}/events`}
            minHeight={300}
          >
            <NewsByEvents
              handleSectionTitle={handleSectionTitle}
            />
          </SectionWrapper>

          <SectionWrapper
            type="news_by_asset_classes"
            handleSectionTitle={handleSectionTitle}
            videoUrl="https://cityfalcon.wistia.com/medias/k55d2nkan7"
            withFilters
            seeMoreUrl={TOPIC_CLASS_PATH}
            minHeight={300}
          >
            <NewsByAssetClasses
              handleSectionTitle={handleSectionTitle}
            />
          </SectionWrapper>
        </div>
      </div>
    </GeneralFiltersContext.Provider>
  );
};

export default withMetatag(memo(HomePage, () => true), '/');
