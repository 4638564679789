import { memo, useEffect } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import Tooltip from '../../Tooltip';

import { storyPeriodFilters as storyFilters } from '../../../data/homepage/sectionsFilters';

import useStoryFilters from '../../../hooks/useStoryFilters';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export const HomePeriodFilters = ({
  onStoryFilterChange,
  initialFilters = null,
}) => {
  const [storyPeriodFilters, setStoryPeriodFilters] = useStoryFilters(
    initialFilters ?? storyFilters,
  );

  useEffect(() => {
    onStoryFilterChange(storyPeriodFilters.find(({ active }) => active)?.value);
  }, []);

  const setStoriesActiveFilter = (value) => {
    setStoryPeriodFilters((currentFilters) => (
      currentFilters.map((filter) => ({
        ...filter,
        active: filter.value === value,
      }))
    ));
    onStoryFilterChange(value);
  };

  return (
    <div className={Styles['home-period-filters']}>
      <ul className={Styles['home-period-filters-options']}>
        {storyPeriodFilters.map((filter) => (
          <li
            key={filter.value}
            className={cx('home-period-filters-option', { active: filter.active })}
            onClick={() => setStoriesActiveFilter(filter.value)}
          >
            <Tooltip
              icon={filter.text}
              className={Styles['home-period-filters-tooltip']}
              disableOnMobile
              hasBigScreenDesign
            >
              {filter.tooltip}
            </Tooltip>
          </li>
        ))}
      </ul>
    </div>
  );
};

HomePeriodFilters.propTypes = {
  onStoryFilterChange: PropTypes.func,
};

HomePeriodFilters.defaultProps = {
  onStoryFilterChange() {},
};

export default memo(HomePeriodFilters);
