import {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import pluralize from 'pluralize';
import debounce from 'lodash-es/debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft as faChevronLeftRegular } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faSquare } from '@fortawesome/pro-regular-svg-icons/faSquare';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faCheckSquare } from '@fortawesome/pro-regular-svg-icons/faCheckSquare';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import { faChevronRight as faChevronRightLight } from '@fortawesome/pro-light-svg-icons/faChevronRight';

import LocationsSvc from '../../../../services/dbServices/LocationsSvc';
import StorageSvc from '../../../../services/StorageSvc';

import ItemsScrollBar from '../../../Shared/ItemsScrollBar';
import Preloader from '../../../Preloader';
import Tooltip from '../../../Tooltip';

import {
  locationTitlesMapping,
  locationTypes,
  parentTypeMapping,
  childTypes,
  locationTypesTitles,
} from '../../../../data/homepage/locationTypes';
import { LOCATION_TYPES, CONTINENT } from '../../../../data/directory/topicTypes';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export const LocationFilter = ({
  firstLevelFilter,
  title,
  closeCallback,
  filterType,
  parentType,
  parentId,
  selectedLocations,
  setSelectedLocations,
  setAllLocationsSelected,
}) => {
  const [nextLevelData, setNextLevelData] = useState({
    show: false,
    parentId: null,
    parentName: null,
    parentType: null,
    filterType: null,
  });
  const [types, setTypes] = useState(locationTypes);
  const [searchTypes, setSearchTypes] = useState([
    {
      id: 'all',
      content: 'All',
      active: true,
    },
    ...locationTypes.map((obj) => ({ ...obj, active: false })),
  ]);
  const [closing, setClosing] = useState(false);
  const [query, setQuery] = useState('');
  const [inputActive, setInputActive] = useState(false);
  const [locationData, setLocationData] = useState({
    list: [],
    loading: true,
  });
  const [isSelectAll, setIsSelectAll] = useState(false);

  const { width } = useSelector(({ common }) => (
    common
  ));

  const locationFilterRef = useRef(null);
  const searchInputRef = useRef(null);

  const searchActive = inputActive || !!query;

  useEffect(() => {
    if (!firstLevelFilter) {
      return;
    }

    document.addEventListener('click', closeCallback, false);
    return () => {
      document.removeEventListener('click', closeCallback);
    };
  }, [firstLevelFilter, closeCallback]);

  useEffect(() => {
    const selectedSearchTypes = searchTypes.find(({ active }) => active);
    if (!query || selectedSearchTypes.id !== 'all') {
      return;
    }
    let mounted = true;

    setLocationData((currentState) => ({
      list: currentState.list,
      loading: true,
    }));
    LocationsSvc.searchLocations({ query }).then((result) => {
      if (!mounted) {
        return;
      }

      setLocationData({
        list: Object.keys(locationTypesTitles).flatMap((type) => {
          const locations = result[type];
          locations.forEach((location) => {
            location.type = pluralize.singular(type);
          });

          const typeTitle = {
            id: type,
            name: locationTypesTitles[type],
            isTitle: true,
          };

          return locations.length ? [typeTitle, ...locations] : [];
        }),
        loading: false,
      });
    });

    return () => {
      mounted = false;
    };
  }, [query, searchTypes]);

  useEffect(() => {
    const selectedType = types.find(({ active }) => active);
    const selectedSearchTypes = searchTypes.find(({ active }) => active);
    if (
      (!selectedType && firstLevelFilter)
      || (!filterType && !firstLevelFilter)
      || (searchActive && selectedSearchTypes.id === 'all')
    ) {
      return;
    }
    let mounted = true;

    const currentActiveType = searchActive ? selectedSearchTypes : selectedType;

    setLocationData((currentState) => ({
      list: currentState.list,
      loading: true,
    }));

    LocationsSvc.getLocationsByType(
      filterType || currentActiveType.id,
      {
        ...(!firstLevelFilter && {
          id: parentId,
          parent_type: parentType,
        }),
        ...(query && {
          prefix: query,
        }),
      },
    ).then((result) => {
      if (!mounted) {
        return;
      }

      if (filterType === CONTINENT || currentActiveType.id === CONTINENT) {
        const selectAll = selectedLocations
          .filter((f) => f?.type === CONTINENT).length === result.length;
        setIsSelectAll(selectAll);
      }
      setLocationData({
        list: result,
        loading: false,
      });
    });

    return () => {
      mounted = false;
    };
  }, [
    types, firstLevelFilter,
    filterType, parentId,
    parentType, query, searchTypes,
  ]);

  const onTypeChange = useCallback((id) => {
    setTypes((currentTypes) => currentTypes.map((type) => {
      if (type.active) {
        type.active = false;
      }

      if (type.id === id) {
        type.active = true;
      }

      return type;
    }));
  }, []);

  const onSearchTypeChange = useCallback((id) => {
    setSearchTypes((currentTypes) => currentTypes.map((type) => {
      if (type.active) {
        type.active = false;
      }

      if (type.id === id) {
        type.active = true;
      }

      return type;
    }));
  }, []);

  const search = useCallback(debounce(() => {
    setQuery(searchInputRef.current.value);
  }, 200), []);

  const searchClean = useCallback(() => {
    setQuery('');
    searchInputRef.current.value = '';
  }, []);

  const hideNext = useCallback(() => {
    setNextLevelData({
      show: false,
      parentId: null,
      parentName: null,
      parentType: null,
      filterType: null,
    });
  }, []);

  const showNext = (id, type, name) => {
    setNextLevelData({
      show: true,
      parentId: id,
      parentName: name,
      parentType: type,
      filterType: parentTypeMapping.get(type),
    });
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  const goBack = () => {
    if (searchActive) {
      setInputActive(false);
      searchClean();
      setTypes(locationTypes);
      setSearchTypes([
        {
          id: 'all',
          content: 'All',
          active: true,
        },
        ...locationTypes.map((obj) => ({ ...obj, active: false })),
      ]);
      return;
    }
    if (width < 1025) {
      setClosing(true);
    } else {
      closeCallback();
    }
  };

  const closeSection = () => {
    if (closing) {
      closeCallback();
    }
  };

  const toggleLocation = (item) => {
    const itemIndex = selectedLocations.findIndex((location) => (
      location.id === item.id
      && location.type === item.type
    ));

    let newList;
    if (itemIndex === -1) {
      newList = [
        ...selectedLocations,
        item,
      ];
    } else {
      newList = selectedLocations.filter((location) => !(
        location.id === item.id
        && location.type === item.type
      ));
    }

    if (item.type === CONTINENT) {
      const selectAll = newList
        .filter((f) => f?.type === CONTINENT).length === locationData.list.length;
      setIsSelectAll(selectAll);
      setAllLocationsSelected(selectAll);
      StorageSvc.setItem('selectedHPLocationsSelectAll', JSON.stringify({ selectAll }));
    }

    setSelectedLocations(newList);
    StorageSvc.setItem('selectedHPLocations', JSON.stringify(newList));
  };

  const selectAll = () => {
    let newList = [];
    if (!isSelectAll) {
      newList = locationData.list;
    } else {
      newList = [];
    }
    const oldValue = isSelectAll;
    setAllLocationsSelected(!oldValue);
    StorageSvc.setItem('selectedHPLocationsSelectAll', JSON.stringify({ selectAll: !oldValue }));
    setIsSelectAll(!oldValue);
    setSelectedLocations(newList);
    StorageSvc.setItem('selectedHPLocations', JSON.stringify(newList));
  };

  const inputFocus = () => {
    setInputActive(true);
  };

  const limitReached = selectedLocations.length === 10;
  const isContainentActive = types.find((f) => f.active)?.id === CONTINENT;

  return (
    <div
      ref={locationFilterRef}
      className={cx('location-filter', { closing, 'first-level': firstLevelFilter })}
      onAnimationEnd={closeSection}
      onClick={stopPropagation}
    >
      <div className={cx('location-filter-header')}>
        <FontAwesomeIcon
          icon={faXmark}
          className={cx('close')}
          onClick={closeCallback}
        />
        {(!firstLevelFilter || searchActive) && (
          <div onClick={goBack} className={cx('back')}>
            <FontAwesomeIcon icon={faChevronLeftRegular} />
            Back
          </div>
        )}
        {!searchActive && (
          <div className={cx('title')}>
            <div className={cx('title-text')}>
              {title}
            </div>
            {firstLevelFilter && (
              <Tooltip
                className={cx('title-filters-tooltip')}
                newIcon
                hasBigScreenDesign
              >
                Select up to 10 locations. Parent locations count as one
                (e.g., San Francisco and Los Angeles count as two but California
                contains both and counts as one)
              </Tooltip>
            )}
          </div>
        )}
        <div
          className={cx('location-filter-max-limit', {
            visible: limitReached,
          })}
        >
          You&apos;ve reached the maximum of 10 locations
        </div>
      </div>
      {firstLevelFilter && (
        <div className={cx('location-filter-sub-header', { search: searchActive })}>
          {!searchActive && (
            <ItemsScrollBar
              onClick={onTypeChange}
              data={types}
              scrollBy={2}
              withUnderline
            />
          )}
          <div className={cx('input-container', { active: searchActive })}>
            <FontAwesomeIcon
              className={cx('search-icon')}
              icon={faSearch}
            />
            <input
              type="text"
              placeholder="Search continent, region, country..."
              className={Styles['location-filter-searchbar']}
              defaultValue=""
              onChange={search}
              ref={searchInputRef}
              onFocus={inputFocus}
            />
            {query && (
              <FontAwesomeIcon
                icon={faXmark}
                className={cx('clear-input')}
                onClick={searchClean}
              />
            )}
          </div>
          {searchActive && (
            <ItemsScrollBar
              onClick={onSearchTypeChange}
              data={searchTypes}
              scrollBy={2}
              withUnderline
              className={Styles['location-filter-searchbar-tabs']}
            />
          )}
        </div>
      )}
      {!locationData.loading ? (
        <div className={cx('location-filter-content')}>
          {!locationData.list.length && (
            <div className={cx('location-filter-item-no-data')}>
              No results for your query
            </div>
          )}
          {isContainentActive && firstLevelFilter && !searchActive && locationData.list.length && (
            <div
              className={cx('location-filter-item', {
                active: isSelectAll,
              })}
            >
              <div
                className={cx('location-filter-item-container')}
                onClick={selectAll}
              >
                <FontAwesomeIcon className={cx('item-check')} icon={isSelectAll ? faCheckSquare : faSquare} />
                <span>
                  Select / Unselect All
                </span>
              </div>
            </div>
          )}
          {locationData.list.map((item) => (
            <LocationItem
              key={item.id}
              item={item}
              selectedLocations={selectedLocations}
              nextLevelData={nextLevelData}
              limitReached={limitReached}
              toggleLocation={toggleLocation}
              showNext={showNext}
              searchActive={searchActive}
            />
          ))}
        </div>
      ) : (
        <Preloader loading static centered hasBigScreenDesign />
      )}
      {nextLevelData.show && (
        <LocationFilter
          firstLevelFilter={false}
          title={`${pluralize(locationTitlesMapping.get(nextLevelData.filterType))} IN ${nextLevelData.parentName}`}
          closeCallback={hideNext}
          filterType={nextLevelData.filterType}
          parentType={nextLevelData.parentType}
          parentId={nextLevelData.parentId}
          selectedLocations={selectedLocations}
          setSelectedLocations={setSelectedLocations}
        />
      )}
    </div>
  );
};

const LocationItem = ({
  item, selectedLocations, nextLevelData, limitReached, toggleLocation, showNext,
  searchActive,
}) => {
  const itemSelected = selectedLocations.some((location) => (
    location.id === item.id
    && location.type === item.type
  ));
  const isParent = (
    nextLevelData.show
    && nextLevelData.parentId === item.id
    && nextLevelData.parentType === item.type
  );

  const childTypeKey = childTypes.get(item.type);
  const hasNextChild = !!(item[childTypeKey] || []).length;
  const showChild = parentTypeMapping.get(item.type) !== undefined && hasNextChild;

  const handleItemClick = () => {
    if (item.isTitle) return;
    toggleLocation(item);
  };

  return (
    <div
      className={cx('location-filter-item', {
        disabled: !itemSelected && limitReached,
        parent: isParent,
        active: itemSelected,
        'item-title': item.isTitle,
      })}
    >
      <div
        className={cx('location-filter-item-container')}
        onClick={handleItemClick}
      >
        <FontAwesomeIcon className={cx('item-check')} icon={itemSelected ? faCheckSquare : faSquare} />
        <span className={cx('location-filter-item-container-name')}>
          {item.name}
        </span>
        {showChild && searchActive && (
          <span className={cx('location-filter-item-container-child')}>
            {(item[childTypeKey] || []).map(({ name }) => name).join(', ')}
          </span>
        )}
      </div>
      {showChild && (
        <div
          className={cx('item-right')}
          onClick={() => showNext(item.id, item.type, item.name)}
        >
          <FontAwesomeIcon icon={faChevronRightLight} />
        </div>
      )}
    </div>
  );
};

LocationFilter.propTypes = {
  firstLevelFilter: PropTypes.bool.isRequired,
  title: PropTypes.string,
  filterType: PropTypes.oneOf(LOCATION_TYPES),
  parentType: PropTypes.oneOf(LOCATION_TYPES),
  parentId: PropTypes.number,
  selectedLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.oneOf(LOCATION_TYPES),
      name: PropTypes.string,
    }),
  ).isRequired,
  setSelectedLocations: PropTypes.func.isRequired,
};

LocationFilter.defaultProps = {
  title: 'LOCATION:',
  filterType: null,
  parentType: null,
  parentId: null,
};

export default memo(LocationFilter);
