import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames/bind';
import { InView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isMobileOnly } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';

import LocationFilter from './LocationFilter';
import LayoutFiltersMobile from './LayoutFiltersMobile';
import SimplifiedLayoutFilters from '../SimplifiedLayoutFilters';
import LanguageFilter from '../../StoryFilters/LanguageFilter';
import FiltersMobileModal from '../../StoryFilters/FiltersMobileModal';

import { COUNTRY } from '../../../data/directory/topicTypes';
import { initialState } from '../../../data/filters/storyFilters';
import { checkFlexGapSupport } from '../../../helpers/helpers';

import StorageSvc from '../../../services/StorageSvc';
import LocationsSvc from '../../../services/dbServices/LocationsSvc';

import * as storyFiltersActions from '../../../actions/storyFilters.action';
import * as storyActions from '../../../actions/stories.actions';
import * as watchlistActions from '../../../actions/watchlistActions';
import * as searchActions from '../../../actions/searchActions';
import * as preferencesActions from '../../../actions/preferencesActions';

import GeneralFiltersContext from '../GeneralFiltersContext';
import PiwikContext from '../../../services/piwikContext';
import Tooltip from '../../Tooltip';

import Styles from './styles.module.scss';

import StoryStyles from '../../StoryFilters/story-filters-styles.module.scss';

const cx = classNames.bind(Styles);

export const GeneralFiltersSection = ({
  setSelectedLocations,
  setAllLocationsSelected,
  setGeneralFiltersVisible,
  setGeneralFiltersOpened,
  generalFiltersOpened,
  promobarHeight,
  hiddenFilter = false,
  allLocationsSelected,
  isOpenedChatbotSmallWidth,
}) => {
  const [locationFiltersOpened, setLocationFiltersOpened] = useState(false);
  const [mobileLayoutFiltersOpened, setMobileLayoutFiltersOpened] = useState(false);
  const [initialLocationsSelected, setInitialLocationsSelected] = useState(false);
  const {
    selectedLocations,
  } = useContext(GeneralFiltersContext);
  const { piwikEnabled } = useContext(PiwikContext);
  const [isGapSupport, setIsGapSupport] = useState(true);

  useEffect(() => {
    setIsGapSupport(checkFlexGapSupport());
  }, []);

  const storyFilters = useSelector((state) => (
    state.storyFilters
  ));
  const { width } = useSelector(({ common }) => (
    common
  ));
  const { userToken: token } = useSelector(({ watchlistReducer }) => (
    watchlistReducer
  ));
  const dispatch = useDispatch();
  const actions = useMemo(() => (
    bindActionCreators({
      ...storyFiltersActions,
      ...storyActions,
      ...watchlistActions,
      ...searchActions,
      ...preferencesActions,
    }, dispatch)
  ), [dispatch]);

  const simplifyFilter = storyFilters.user_newsfeed_advanced_view_web;

  const toggleLocationFilterCallback = useCallback((e) => {
    e?.stopPropagation();
    setLocationFiltersOpened((current) => !current);
  }, []);

  const onLocationSelect = useCallback((locations) => {
    setInitialLocationsSelected(true);
    setSelectedLocations(locations);
  }, [setSelectedLocations]);

  const selectedLocationsNames = useMemo(() => {
    if (allLocationsSelected) return 'All locations';
    if (!selectedLocations.length) return 'Select location';
    return selectedLocations
      .map((location) => (
        location.name
      ))
      .sort()
      .join(', ');
  }, [selectedLocations, allLocationsSelected]);

  const toggleMobileSimplifyFilterCallback = useCallback(() => {
    setMobileLayoutFiltersOpened((current) => !current);
  }, []);

  const isCompactView = isMobileOnly ? (
    storyFilters.user_newsfeed_compactview
  ) : (
    storyFilters.user_newsfeed_compactview_web
  );
  const userNewsfeedView = isMobileOnly ? (
    storyFilters.user_newsfeed_view
  ) : (
    storyFilters.user_newsfeed_view_web
  );
  const { filtersInitialized } = storyFilters;
  const selectedSimplifyFilters = useMemo(() => {
    if (!filtersInitialized) {
      return 'Select Layout, Select view';
    }
    let text = `${simplifyFilter ? 'Advanced' : 'Simple'} Layout`;
    if (simplifyFilter === undefined) {
      text = 'Select Layout';
    }
    if (isCompactView) {
      text += ', Compact view';
    } else if (userNewsfeedView === undefined) {
      text += ', Select view';
    } else {
      text += `, ${(userNewsfeedView || '').toLowerCase().replace(/^./, (l) => l.toUpperCase())} view`;
    }
    return text;
  }, [simplifyFilter, isCompactView, userNewsfeedView, filtersInitialized]);

  useEffect(() => {
    let locations;
    try {
      locations = JSON.parse(StorageSvc.getItem('selectedHPLocations'));
      const locationsSelectAll = JSON.parse(StorageSvc.getItem('selectedHPLocationsSelectAll'));
      if (locationsSelectAll?.selectAll) {
        setAllLocationsSelected(locationsSelectAll.selectAll);
      }
      if (Array.isArray(locations)) {
        setSelectedLocations(locations);
        setInitialLocationsSelected(true);
      } else {
        locations = null;
      }
    } catch {
      locations = null;
    }

    if (locations || selectedLocations.length) {
      return;
    }
    LocationsSvc.getLocationsByType(COUNTRY, {
      prefix: 'United',
      parent_type: undefined,
    }).then((assets) => {
      const list = assets.filter((asset) => ['US', 'GB'].includes(asset.short_name));
      setSelectedLocations(list);
    });
  }, []);

  return (
    <InView
      as="div"
      className={cx('general-filters-section', {
        'hidden-filter': hiddenFilter,
        stick: generalFiltersOpened,
        'tablet-version': isOpenedChatbotSmallWidth,
      })}
      onChange={(inView) => setGeneralFiltersVisible(inView)}
      style={hiddenFilter && generalFiltersOpened ? { marginTop: `${promobarHeight}px` } : {}}
    >
      <FontAwesomeIcon
        icon={faXmark}
        className={cx('close')}
        onClick={() => setGeneralFiltersOpened(false)}
      />
      <div className="position-relative">
        <div className={Styles.title_row}>
          <span className={Styles.title}>
            General filters and settings
          </span>
          <Tooltip
            hasBigScreenDesign
            className={cx('general-filters-tooltip')}
          >
            Restrict news to your preferred locations and set languages for news content.
          </Tooltip>
        </div>
        <div className={cx('filters-wrapper', { no_gap: !isGapSupport })}>
          <div
            className={Styles['location-filters']}
            onClick={toggleLocationFilterCallback}
          >
            <span className={cx('selected-values', { initialized: initialLocationsSelected })}>
              {selectedLocationsNames}
            </span>
            <div className={cx('expand-icon', { expanded: locationFiltersOpened })}>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {locationFiltersOpened && (
              <>
                {width >= 1025 ? (
                  <LocationFilter
                    firstLevelFilter
                    closeCallback={toggleLocationFilterCallback}
                    selectedLocations={selectedLocations}
                    setSelectedLocations={onLocationSelect}
                    setAllLocationsSelected={setAllLocationsSelected}
                  />
                ) : (
                  <FiltersMobileModal
                    openFilter={locationFiltersOpened}
                    classname={cx('location-filter-modal')}
                  >
                    <LocationFilter
                      firstLevelFilter
                      closeCallback={toggleLocationFilterCallback}
                      selectedLocations={selectedLocations}
                      setSelectedLocations={onLocationSelect}
                      setAllLocationsSelected={setAllLocationsSelected}
                    />
                  </FiltersMobileModal>
                )}
              </>
            )}
          </div>
          <div className={`${Styles['language-filter']} ${StoryStyles['story-filters']} ${StoryStyles.big_screen}`}>
            <LanguageFilter
              dropdownTitle="Languages"
              action={actions.filterByLanguage}
              translationValue={storyFilters.translate_all}
              translateAction={actions.showTranslation}
              selectedAllLanguages={storyFilters.all_languages}
              defaultSelectedLanguages={initialState.languages}
              toggleAllLanguagesSelected={actions.toggleAllLanguagesSelected}
              freezeStories={() => null}
              savedFilterValue={{
                lang: storyFilters.lang,
                all_languages: storyFilters.all_languages,
              }}
              width={width}
              rbi={false}
              piwikEnabled={piwikEnabled}
              token={token}
              hasBigScreenDesign
              className={Styles['language-filter-wrapper']}
              hideTranslationBar={false}
              hideLabel
              homePage
              hiddenFilter={hiddenFilter}
            />
          </div>
          <div className={Styles['simplified-layout-filters-desktop']}>
            <SimplifiedLayoutFilters />
          </div>
          <div
            className={Styles['simplified-layout-filters-tablet']}
            onClick={toggleMobileSimplifyFilterCallback}
          >
            <span className={cx('selected-values')}>
              {selectedSimplifyFilters}
            </span>
            <div className={cx('expand-icon', { expanded: mobileLayoutFiltersOpened })}>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
          {mobileLayoutFiltersOpened && (
            <FiltersMobileModal
              openFilter={mobileLayoutFiltersOpened}
              classname={cx('layout-filter-modal')}
              handleCloseModal={() => setMobileLayoutFiltersOpened(false)}
            >
              <LayoutFiltersMobile
                isSimpleLayout={simplifyFilter}
                onClose={toggleMobileSimplifyFilterCallback}
              />
            </FiltersMobileModal>
          )}
        </div>
      </div>
    </InView>
  );
};

export default memo(GeneralFiltersSection);
