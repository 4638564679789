import integratedApiImage from '../../assets/homepage/server.svg';
import birdsImage from '../../assets/homepage/birds.png';
import birdsMobileImage from '../../assets/homepage/birds-mobile.png';

export const promoSectionIntegratedApi = {
  iconData: {
    src: integratedApiImage,
    width: 78,
    height: 73,
  },
  iconDataMobile: {
    src: integratedApiImage,
    width: 68,
    height: 66,
  },
  title: 'Access All Our Content And Features Through Our API',
  subtitle: 'or use single sign-on to give premium access to your organisation.',
  buttonText: 'Learn More',
  buttonUrl: '/products/api/financial-news',
  useAnchor: true,
  additionalStyles: 'integrated-api',
};

export const promoSectionPricing = {
  iconData: {
    src: birdsImage,
    width: 124,
    height: 97,
  },
  iconDataMobile: {
    src: birdsMobileImage,
    width: 135,
    height: 92,
  },
  title: 'Upgrade to Access More Insights and Premium Content',
  subtitle: 'with our Premium plans.',
  buttonText: 'Compare Plans',
  buttonUrl: '/pricing',
  pricing: true,
  additionalStyles: 'upgrade-plan',
};
